import VueGtm from '@gtm-support/vue2-gtm'
import Vue from 'vue'
import { Plugin } from '@nuxt/types'

const gtm: Plugin = context => {
  Vue.use(VueGtm, {
    id: context.$config.GOOGLE_TAG_MANAGER_ID,
    enabled: true,
  })
}

export default gtm
