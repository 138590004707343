import { mutationTree, actionTree, getterTree } from 'typed-vuex'
import { StoreError } from '@/types/error'
import { EMPTY_ERROR } from '@/constants/error'

interface ErrorState {
  error: StoreError
}

export const state = (): ErrorState => ({
  error: { ...EMPTY_ERROR },
})

export const getters = getterTree(state, {
  getError: (state: ErrorState): StoreError => {
    return state.error
  },
})

export const mutations = mutationTree(state, {
  SET_Error: (state: ErrorState, error: StoreError): void => {
    state.error = error
  },
})

export const actions = actionTree(
  { state, mutations },
  {
    setError({ commit }, error: StoreError): void {
      commit('SET_Error', error)
    },
    resetError({ commit }): void {
      commit('SET_Error', { ...EMPTY_ERROR })
    },
  }
)
