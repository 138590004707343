








import Vue from 'vue'
export default Vue.extend({
  name: 'Loading',
  computed: {
    isLoading(): boolean {
      return this.$accessor.loading.isLoading
    },
  },
})
