





















import Vue from 'vue'

export default Vue.extend({
  props: {
    isDialogShow: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClickClose(): void {
      this.$emit('onClickClose')
    },
  },
})
