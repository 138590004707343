import { BlockBlobClient } from '@azure/storage-blob'
import { AbortSignal } from '@azure/abort-controller'
import { AxiosRequestConfig } from 'axios'
import { FileApi } from '@/api/api'
import { toCamelCase } from '@/utils/common'

export interface AttachmentFileEntity {
  id: number
  name: string
  mimeType: string
  sasFile: string
  sasThumbnail: string
  isProtected: boolean
  filePermissions: string[]
  status: number
}

export interface DirectoryFileEntity {
  id: number
  name: string
  isProtected: boolean
  isDirectory: boolean
  contentCreatedAt?: string
  createdAt?: string
  mimeType?: string
  masterId?: number
  size?: number
  status?: number
  sasFile: string
  sasThumbnail?: string
  userId?: number
  filePermissions?: string[]
}

export interface FileRawEntity {
  raw: File
  isProtected: boolean
}

export interface FileCreateRequestEntity {
  name: string
  path: string
  isProtected: boolean
}

export interface FileUpdateRequestEntity {
  id: number
  name?: string
  path?: string
  isProtected?: boolean
}
export interface AttachmentOperationsEntity {
  uuid?: string
  createFiles?: FileCreateRequestEntity[]
  deleteFiles?: number[]
  updateFiles?: FileUpdateRequestEntity[]
}

export interface BulkFileOperationEntity {
  body?: string
  mentions?: number[]
  attachments?: AttachmentOperationsEntity
}

export interface SasEntity {
  name?: string
  path: string
  sas: string
}

export class FileRepository {
  private readonly fileApi: FileApi
  constructor(fileApi: FileApi) {
    this.fileApi = fileApi
  }

  async getUuid(options?: AxiosRequestConfig): Promise<string> {
    const { data } = await this.fileApi.getUuid(options)
    if (data?.data && data.ok) {
      return data?.data.uuid
    } else {
      return ''
    }
  }

  async getSas(uuid: string, name: string, options?: AxiosRequestConfig): Promise<SasEntity | undefined> {
    const { data } = await this.fileApi.getSas(uuid, name, options)
    if (data?.data && data.ok) {
      return toCamelCase(data.data)
    } else {
      return undefined
    }
  }

  async upload(
    file: File,
    uuid: string,
    abortSignal?: AbortSignal,
    options?: AxiosRequestConfig
  ): Promise<SasEntity | null> {
    const sasResponse = await this.getSas(uuid, file.name, options)
    if (sasResponse) {
      const client = new BlockBlobClient(sasResponse.sas)
      await client.uploadData(file, {
        abortSignal,
        blockSize: 4 * 1024 * 1024,
        concurrency: 20,
        blobHTTPHeaders: { blobContentType: file.type },
      })
      if (typeof sasResponse !== 'undefined') {
        return sasResponse
      }
    }
    return null
  }
}
