














import Vue from 'vue'
import PageHeader from '@/components/common/PageHeader.vue'

export default Vue.extend({
  name: 'NotFound',
  components: { PageHeader },
  props: {
    statusCode: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onClickHomeButton(): void {
      this.$accessor.error.resetError()
      this.$router.push('/properties/')
    },
  },
})
