import { AxiosRequestConfig } from 'axios'
import { ShotPointApi, ShotPointCreateRequest, ShotPointUpdateRequest, VrCoordinates } from '@/api/api'
import { toCamelCase } from '@/utils/common'
import { IssueEntity } from '@/repository/IssueRepository'
import { FacilityEntity } from '@/repository/FacilityRepository'

export interface ShotPositionEntity {
  latitude: number
  longitude: number
}

export interface VrCoordinatesEntity {
  pitch: number
  yaw: number
  hfov?: number
}

export enum PvrObjectContentType {
  Issue = 'issue',
  Facility = 'facility',
}

export interface PvrObjectContentEntity {
  type: PvrObjectContentType
  category: number
  title: string
  description: string
  resourceId: string
}

export interface PvrObjectEntity {
  id: number
  vrCoordinates: VrCoordinatesEntity
  content: PvrObjectContentEntity
  issue?: IssueEntity | null
  facility?: FacilityEntity | null
}

export interface PanoramaEntity {
  id: number
  name: string
  sasFile: string
  sasThumbnail?: string
  multiresPath: string
  defaultHorizontalDegree: number
  directionDegreeOnFloorPlan: number
  contentCreatedAt?: string
  status: number
  pvrObjects?: PvrObjectEntity[]
}

export interface ShotPointEntity {
  id: number
  name: string
  shotPosition: ShotPositionEntity
  panoramaCount: number
  latestPanorama: PanoramaEntity
}

export interface LinkPanoramaUpdateRequestLinkEntity {
  shotPointId: number
  vrCoordinates: VrCoordinates
}
export interface LinkPanoramaUpdateRequestPatchEntity {
  vrCoordinates: VrCoordinates
}
export interface LinkPanoramaCreateEntity {
  link: LinkPanoramaUpdateRequestLinkEntity
}

export interface LinkPanoramaUpdateEntity {
  link?: LinkPanoramaUpdateRequestLinkEntity
  patch?: LinkPanoramaUpdateRequestPatchEntity
  unlink?: boolean
}

export class ShotPointRepository {
  private readonly shotpointApi: ShotPointApi

  constructor(shotpointApi: ShotPointApi) {
    this.shotpointApi = shotpointApi
  }

  async fetchShotPointDetails(
    propertyId: number,
    floorId: number,
    shotPointId: number,
    options?: AxiosRequestConfig
  ): Promise<ShotPointEntity | null> {
    const { data } = await this.shotpointApi.getShotPoint(propertyId, floorId, shotPointId, options)
    if (data?.data && data.ok) {
      return toCamelCase(data.data)
    } else {
      return null
    }
  }

  async createShotpoint(
    propertyId: number,
    floorId: number,
    request: ShotPointCreateRequest,
    options?: AxiosRequestConfig
  ): Promise<ShotPointEntity | null> {
    const { data } = await this.shotpointApi.createShotPoint(propertyId, floorId, request, options)
    if (data?.data && data.ok) {
      return toCamelCase(data.data)
    } else {
      return null
    }
  }

  async updatePanorama(
    propertyId: number,
    floorId: number,
    shotPointId: number,
    request: ShotPointUpdateRequest,
    options?: AxiosRequestConfig
  ): Promise<ShotPointEntity | null> {
    const { data } = await this.shotpointApi.updateShotPoint(propertyId, floorId, shotPointId, request, options)
    if (data?.data && data.ok) {
      return toCamelCase(data.data)
    } else {
      return null
    }
  }
}
