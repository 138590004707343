import { mutationTree, actionTree, getterTree } from 'typed-vuex'

export interface LoadingState {
  isLoading: boolean
}

export const state = (): LoadingState => ({
  isLoading: false,
})

export const getters = getterTree(state, {
  isLoading: state => {
    return state.isLoading
  },
})

export const mutations = mutationTree(state, {
  SET_LoadingState: (state, isLoading: boolean) => {
    state.isLoading = isLoading
  },
})

export const actions = actionTree(
  { state, mutations },
  {
    setLoading({ commit }, isLoading: boolean): void {
      commit('SET_LoadingState', isLoading)
    },
  }
)
