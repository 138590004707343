import { Plugin } from '@nuxt/types'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { CancelTokenStatic } from 'axios'
import {
  CommentApi,
  IssueApi,
  FacilityApi,
  FileApi,
  FloorApi,
  PropertyApi,
  StatusApi,
  UserApi,
  ShotPointApi,
  NotificationSettingsApi,
  PropertyDirectoryMasterApi,
  PublicApi,
  PanoramaApi,
  StaticFileApi,
  SettingsApi,
} from '@/api/api'
import { Configuration } from '@/api/configuration'
import { CommentRepository } from '@/repository/CommentRepository'
import { FileRepository } from '@/repository/FileRepository'
import { IssueRepository } from '@/repository/IssueRepository'
import { FacilityRepository } from '@/repository/FacilityRepository'
import { PropertyRepository } from '@/repository/PropertyRepository'
import { UserRepository } from '@/repository/UserRepository'
import { StatusRepository } from '@/repository/StatusRepository'
import { FacilityTypeRepository } from '@/repository/FacilityTypeRepository'
import { ShotPointRepository } from '@/repository/ShotPointRepository'
import { NotificationSettingRepository } from '@/repository/NotificationSettingRepository'
import { PropertyDirectoryMasterRepository } from '@/repository/PropertyDirectoryMasterRepository'
import { PanoramaRepository } from '@/repository/PanoramaRepository'
import { StaticFileRepository } from '@/repository/StaticFileRepository'
import { SettingsRepository } from '@/repository/SettingsRepository'

let baseAxios: NuxtAxiosInstance
export function initializeAxios(axios: NuxtAxiosInstance) {
  baseAxios = axios
}

export interface Repositories {
  cancelToken: CancelTokenStatic
  comment: CommentRepository
  file: FileRepository
  issue: IssueRepository
  facility: FacilityRepository
  property: PropertyRepository
  shotPoint: ShotPointRepository
  status: StatusRepository
  facilityType: FacilityTypeRepository
  user: UserRepository
  notificationSetting: NotificationSettingRepository
  propertyDirectoryMaster: PropertyDirectoryMasterRepository
  panorama: PanoramaRepository
  staticFile: StaticFileRepository
  settings: SettingsRepository
}

const repositories: Plugin = (context, inject) => {
  const BASE_PATH = `${
    process.client ? `${window.location.protocol}//${window.location.hostname}` : context.$config.API_HOST_SERVER
  }/api/v1/property-hub`
  const axiosConfig = new Configuration({
    basePath: BASE_PATH,
  })

  const comment = new CommentRepository(new CommentApi(axiosConfig, BASE_PATH, baseAxios))
  const file = new FileRepository(new FileApi(axiosConfig, BASE_PATH, baseAxios))
  const issue = new IssueRepository(
    new IssueApi(axiosConfig, BASE_PATH, baseAxios),
    new PublicApi(axiosConfig, BASE_PATH, baseAxios)
  )
  const facility = new FacilityRepository(
    new FacilityApi(axiosConfig, BASE_PATH, baseAxios),
    new PublicApi(axiosConfig, BASE_PATH, baseAxios)
  )
  const property = new PropertyRepository(
    new PropertyApi(axiosConfig, BASE_PATH, baseAxios),
    new FloorApi(axiosConfig, BASE_PATH, baseAxios),
    new FileApi(axiosConfig, BASE_PATH, baseAxios),
    new PublicApi(axiosConfig, BASE_PATH, baseAxios)
  )
  const shotPoint = new ShotPointRepository(new ShotPointApi(axiosConfig, BASE_PATH, baseAxios))
  const status = new StatusRepository(new StatusApi(axiosConfig, BASE_PATH, baseAxios))
  const facilityType = new FacilityTypeRepository(new FacilityApi(axiosConfig, BASE_PATH, baseAxios))
  const user = new UserRepository(new UserApi(axiosConfig, BASE_PATH, baseAxios))
  const notificationSetting = new NotificationSettingRepository(
    new NotificationSettingsApi(axiosConfig, BASE_PATH, baseAxios)
  )
  const propertyDirectoryMaster = new PropertyDirectoryMasterRepository(
    new PropertyDirectoryMasterApi(axiosConfig, BASE_PATH, baseAxios)
  )
  const settings = new SettingsRepository(new SettingsApi(axiosConfig, BASE_PATH, baseAxios))

  const panorama = new PanoramaRepository(new PanoramaApi(axiosConfig, BASE_PATH, baseAxios))
  const staticFile = new StaticFileRepository(new StaticFileApi(axiosConfig, BASE_PATH, baseAxios))
  const repositories: Repositories = {
    cancelToken: baseAxios.CancelToken,
    comment,
    file,
    issue,
    facility,
    property,
    shotPoint,
    status,
    facilityType,
    user,
    notificationSetting,
    propertyDirectoryMaster,
    panorama,
    settings,
    staticFile,
  }
  inject('repositories', repositories)
}
export default repositories
