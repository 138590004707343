import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2fd457cc = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _5da6ec34 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e0198f6a = () => interopDefault(import('../pages/issues/index.vue' /* webpackChunkName: "pages/issues/index" */))
const _4364e484 = () => interopDefault(import('../pages/properties/index.vue' /* webpackChunkName: "pages/properties/index" */))
const _34e0acf4 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _6fa965a4 = () => interopDefault(import('../pages/auth/callback.vue' /* webpackChunkName: "pages/auth/callback" */))
const _a9dd5b86 = () => interopDefault(import('../pages/facilities/_id/index.vue' /* webpackChunkName: "pages/facilities/_id/index" */))
const _22dd9494 = () => interopDefault(import('../pages/issues/_id/index.vue' /* webpackChunkName: "pages/issues/_id/index" */))
const _f167a238 = () => interopDefault(import('../pages/properties/_propertyId/index.vue' /* webpackChunkName: "pages/properties/_propertyId/index" */))
const _7322e7a0 = () => interopDefault(import('../pages/facilities/_id/floorplan.vue' /* webpackChunkName: "pages/facilities/_id/floorplan" */))
const _5a8471da = () => interopDefault(import('../pages/facilities/_id/panorama.vue' /* webpackChunkName: "pages/facilities/_id/panorama" */))
const _64a26699 = () => interopDefault(import('../pages/issues/_id/floorplan.vue' /* webpackChunkName: "pages/issues/_id/floorplan" */))
const _5a0cafc1 = () => interopDefault(import('../pages/issues/_id/panorama.vue' /* webpackChunkName: "pages/issues/_id/panorama" */))
const _2a045c5f = () => interopDefault(import('../pages/properties/_propertyId/facilities.vue' /* webpackChunkName: "pages/properties/_propertyId/facilities" */))
const _6a046609 = () => interopDefault(import('../pages/properties/_propertyId/files.vue' /* webpackChunkName: "pages/properties/_propertyId/files" */))
const _5255b2f0 = () => interopDefault(import('../pages/properties/_propertyId/floors/index.vue' /* webpackChunkName: "pages/properties/_propertyId/floors/index" */))
const _e13689d0 = () => interopDefault(import('../pages/properties/_propertyId/issues.vue' /* webpackChunkName: "pages/properties/_propertyId/issues" */))
const _236bae7e = () => interopDefault(import('../pages/properties/_propertyId/shotpoints/index.vue' /* webpackChunkName: "pages/properties/_propertyId/shotpoints/index" */))
const _1607e130 = () => interopDefault(import('../pages/properties/_propertyId/floors/_floorId/shotpoints/_id/index.vue' /* webpackChunkName: "pages/properties/_propertyId/floors/_floorId/shotpoints/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404/",
    component: _2fd457cc,
    pathToRegexpOptions: {"strict":true},
    name: "404___ja"
  }, {
    path: "/en*/",
    component: _2fd457cc,
    name: "not-found___en"
  }, {
    path: "/en/",
    component: _5da6ec34,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/issues/",
    component: _e0198f6a,
    pathToRegexpOptions: {"strict":true},
    name: "issues___ja"
  }, {
    path: "/properties/",
    component: _4364e484,
    pathToRegexpOptions: {"strict":true},
    name: "properties___ja"
  }, {
    path: "/settings/",
    component: _34e0acf4,
    pathToRegexpOptions: {"strict":true},
    name: "settings___ja"
  }, {
    path: "/auth/callback/",
    component: _6fa965a4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-callback___ja"
  }, {
    path: "/en/404/",
    component: _2fd457cc,
    pathToRegexpOptions: {"strict":true},
    name: "404___en"
  }, {
    path: "/en/issues/",
    component: _e0198f6a,
    pathToRegexpOptions: {"strict":true},
    name: "issues___en"
  }, {
    path: "/en/properties/",
    component: _4364e484,
    pathToRegexpOptions: {"strict":true},
    name: "properties___en"
  }, {
    path: "/en/settings/",
    component: _34e0acf4,
    pathToRegexpOptions: {"strict":true},
    name: "settings___en"
  }, {
    path: "/en/auth/callback/",
    component: _6fa965a4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-callback___en"
  }, {
    path: "/en/facilities/:id?/",
    component: _a9dd5b86,
    pathToRegexpOptions: {"strict":true},
    name: "facilities-id___en"
  }, {
    path: "/en/issues/:id/",
    component: _22dd9494,
    pathToRegexpOptions: {"strict":true},
    name: "issues-id___en"
  }, {
    path: "/en/properties/:propertyId/",
    component: _f167a238,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId___en"
  }, {
    path: "/en/facilities/:id?/floorplan/",
    component: _7322e7a0,
    pathToRegexpOptions: {"strict":true},
    name: "facilities-id-floorplan___en"
  }, {
    path: "/en/facilities/:id?/panorama/",
    component: _5a8471da,
    pathToRegexpOptions: {"strict":true},
    name: "facilities-id-panorama___en"
  }, {
    path: "/en/issues/:id/floorplan/",
    component: _64a26699,
    pathToRegexpOptions: {"strict":true},
    name: "issues-id-floorplan___en"
  }, {
    path: "/en/issues/:id/panorama/",
    component: _5a0cafc1,
    pathToRegexpOptions: {"strict":true},
    name: "issues-id-panorama___en"
  }, {
    path: "/en/properties/:propertyId/facilities/",
    component: _2a045c5f,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-facilities___en"
  }, {
    path: "/en/properties/:propertyId/files/",
    component: _6a046609,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-files___en"
  }, {
    path: "/en/properties/:propertyId/floors/",
    component: _5255b2f0,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-floors___en"
  }, {
    path: "/en/properties/:propertyId/issues/",
    component: _e13689d0,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-issues___en"
  }, {
    path: "/en/properties/:propertyId/shotpoints/",
    component: _236bae7e,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-shotpoints___en"
  }, {
    path: "/en/properties/:propertyId/floors/:floorId/shotpoints/:id?/",
    component: _1607e130,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-floors-floorId-shotpoints-id___en"
  }, {
    path: "/facilities/:id?/",
    component: _a9dd5b86,
    pathToRegexpOptions: {"strict":true},
    name: "facilities-id___ja"
  }, {
    path: "/issues/:id/",
    component: _22dd9494,
    pathToRegexpOptions: {"strict":true},
    name: "issues-id___ja"
  }, {
    path: "/properties/:propertyId/",
    component: _f167a238,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId___ja"
  }, {
    path: "/facilities/:id?/floorplan/",
    component: _7322e7a0,
    pathToRegexpOptions: {"strict":true},
    name: "facilities-id-floorplan___ja"
  }, {
    path: "/facilities/:id?/panorama/",
    component: _5a8471da,
    pathToRegexpOptions: {"strict":true},
    name: "facilities-id-panorama___ja"
  }, {
    path: "/issues/:id/floorplan/",
    component: _64a26699,
    pathToRegexpOptions: {"strict":true},
    name: "issues-id-floorplan___ja"
  }, {
    path: "/issues/:id/panorama/",
    component: _5a0cafc1,
    pathToRegexpOptions: {"strict":true},
    name: "issues-id-panorama___ja"
  }, {
    path: "/properties/:propertyId/facilities/",
    component: _2a045c5f,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-facilities___ja"
  }, {
    path: "/properties/:propertyId/files/",
    component: _6a046609,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-files___ja"
  }, {
    path: "/properties/:propertyId/floors/",
    component: _5255b2f0,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-floors___ja"
  }, {
    path: "/properties/:propertyId/issues/",
    component: _e13689d0,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-issues___ja"
  }, {
    path: "/properties/:propertyId/shotpoints/",
    component: _236bae7e,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-shotpoints___ja"
  }, {
    path: "/properties/:propertyId/floors/:floorId/shotpoints/:id?/",
    component: _1607e130,
    pathToRegexpOptions: {"strict":true},
    name: "properties-propertyId-floors-floorId-shotpoints-id___ja"
  }, {
    path: "*/",
    component: _2fd457cc,
    name: "not-found___ja"
  }, {
    path: "/",
    component: _5da6ec34,
    pathToRegexpOptions: {"strict":true},
    name: "index___ja"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
