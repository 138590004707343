import { mutationTree, actionTree, getterTree } from 'typed-vuex'
import { SettingType } from '@/types/setting'
import { GlobalIssueSettingEntity, GlobalFacilitySettingEntity } from '@/repository/SettingsRepository'

export interface FileState {
  type: SettingType
  issueMasterSetting: GlobalIssueSettingEntity
  facilityMasterSetting: GlobalFacilitySettingEntity
}

export const state = (): FileState => ({
  type: 'NOTIFICATION',
  issueMasterSetting: {
    prefix: '',
    protectedFileAlias: '',
  },
  facilityMasterSetting: {
    prefix: '',
  },
})

export const getters = getterTree(state, {
  getType: state => {
    return state.type
  },
  getIssueMasterSetting: state => {
    return state.issueMasterSetting
  },
  getFacilityMasterSetting: state => {
    return state.facilityMasterSetting
  },
})

export const mutations = mutationTree(state, {
  SET_Type: (state, type: SettingType) => {
    state.type = type
  },
  SET_IssueMasterSetting: (state, setting: GlobalIssueSettingEntity) => {
    state.issueMasterSetting = setting
  },
  SET_FacilityMasterSetting: (state, setting: GlobalFacilitySettingEntity) => {
    state.facilityMasterSetting = setting
  },
})

export const actions = actionTree(
  { state, mutations },
  {
    setType({ commit }, type: SettingType): void {
      commit('SET_Type', type)
    },
    setIssueMasterSetting({ commit }, setting: GlobalIssueSettingEntity): void {
      commit('SET_IssueMasterSetting', setting)
    },
    setFacilityMasterSetting({ commit }, setting: GlobalFacilitySettingEntity): void {
      commit('SET_FacilityMasterSetting', setting)
    },
  }
)
