import { Context } from '@nuxt/types'
import { NavigationGuardNext, Route } from 'vue-router'
import { FacilityTypeEntity } from '@/repository/FacilityTypeRepository'

export default ({ app }: Context): void => {
  app.router?.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    const fromPath: string[] = from.path.split('/').filter(val => val)
    const toPath: string[] = to.path.split('/').filter(val => val)
    if (toPath[0] === 'auth' && toPath[1] === 'callback' && toPath.length === 2) {
      next()
    }

    if (process.client && app.$auth.loggedIn && !app.$accessor.user.allUsers.length) {
      app.$accessor.loading.setLoading(true)
      try {
        // 基本的にマスターデータは全て取得 or 全て未取得のため、性能を考慮して一括取得する
        const queue: Promise<any>[] = [
          app.$repositories.user.getUserDivisions(),
          app.$repositories.user.getUserRoles(),
          app.$repositories.user.getUsers(),
          app.$repositories.user.getUserMe(),
          app.$repositories.status.getStatus(),
          app.$repositories.facilityType.getFacilityTypes(),
        ]
        const [divisions, roles, users, loginUser, statuses, types] = await Promise.all(queue)
        app.$accessor.user.setDivisions(divisions)
        app.$accessor.user.setRoles(roles)
        app.$accessor.user.setUsers(users)
        app.$accessor.user.setLoginUser(loginUser)
        app.$accessor.issue.setStatuses(statuses)
        app.$accessor.facility.setTypes(
          (types as FacilityTypeEntity[]).map(type => {
            return {
              ...type,
              name: type.name.replaceAll('info-', app.i18n.t('common.facility-type-name-prefix').toString()),
            }
          })
        )
      } catch (error) {
        console.log(error)
      } finally {
        app.$accessor.loading.setLoading(false)
      }
    }

    if (
      toPath[0] === 'properties' &&
      toPath[2] === 'files' &&
      toPath.length === 3 &&
      !app.$accessor.user.loginUser?.isShowingDirectory
    ) {
      app.$accessor.error.setError({
        statusCode: 404,
        errorCode: null,
        message: '',
        showDialog: false,
        showSnackbar: false,
      })
    }

    next()
  })
}
