import { AxiosRequestConfig } from 'axios'
import { PanoramaApi } from '@/api/api'
import { toCamelCase } from '@/utils/common'
import { PanoramaEntity } from '@/repository/ShotPointRepository'

export type PanoramaModeType = 'view' | 'edit' | 'position-edit'

export class PanoramaRepository {
  private readonly panoramaApi: PanoramaApi

  constructor(panoramaApi: PanoramaApi) {
    this.panoramaApi = panoramaApi
  }

  async fetchPanoramaDetails(
    propertyId: number,
    floorId: number,
    shotPointId: number,
    panoramaId: number,
    options?: AxiosRequestConfig
  ): Promise<PanoramaEntity | null> {
    const { data } = await this.panoramaApi.getPanorama(propertyId, floorId, shotPointId, panoramaId, options)
    if (data?.data && data.ok) {
      return toCamelCase(data.data)
    } else {
      return null
    }
  }

  async deletePanorama(
    propertyId: number,
    floorId: number,
    shotPointId: number,
    panoramaId: number,
    options?: AxiosRequestConfig
  ): Promise<boolean> {
    try {
      const { data } = await this.panoramaApi.deletePanorama(propertyId, floorId, shotPointId, panoramaId, options)
      if (!data.ok) {
        throw new Error('API Error')
      }
      return true
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async fetchPanoramaHistories(
    propertyId: number,
    floorId: number,
    shotPointId: number,
    options?: AxiosRequestConfig
  ): Promise<PanoramaEntity[] | null> {
    const { data } = await this.panoramaApi.listPanoramaHistories(propertyId, floorId, shotPointId, options)
    if (data?.data && data.ok) {
      return toCamelCase(data.data)
    } else {
      return null
    }
  }
}
