












































































import Vue from 'vue'

interface SideMenuState {
  miniVariant: boolean
  isNarrowWindowWidth: boolean
  windowInnerWidth: number
}

export default Vue.extend({
  data(): SideMenuState {
    return {
      miniVariant: true, // 最初は閉じている
      isNarrowWindowWidth: false,
      windowInnerWidth: 0,
    }
  },
  mounted() {
    window.addEventListener('resize', this.setNavigationToWindowWidth)
    this.$nextTick(() => {
      this.windowInnerWidth = window.innerWidth
      this.isNarrowWindowWidth = this.windowInnerWidth < 1152
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setNavigationToWindowWidth)
  },
  methods: {
    onClickExpand(): void {
      this.miniVariant = !this.miniVariant
    },
    setNavigationToWindowWidth() {
      if (this.windowInnerWidth === window.innerWidth) {
        return
      }
      this.windowInnerWidth = window.innerWidth
      if (this.windowInnerWidth < 1152) {
        this.isNarrowWindowWidth = true
        this.miniVariant = true
      } else {
        this.isNarrowWindowWidth = false
      }
    },
    onClickPropertiesLink(): void {
      this.$accessor.session.setHistoryBack(false)
      this.$accessor.error.resetError()
      this.$router.push({ path: '/properties/' })
    },
    onClickIssuesLink(): void {
      this.$accessor.session.setHistoryBack(false)
      this.$accessor.error.resetError()
      this.$router.push({ path: '/issues/' })
    },
  },
})
