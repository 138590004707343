import { mutationTree, actionTree, getterTree } from 'typed-vuex'
import {
  FilterFacilityEntity,
  FacilityDetailEntity,
  FacilityEntity,
  LinkedPanoramaEntity,
  PaginationEntity,
} from '@/repository/FacilityRepository'
import { FacilityTypeEntity } from '@/repository/FacilityTypeRepository'
import { FacilityListRequestOrigin } from '@/types/facility'
import { PvrObjectInfo } from '@/types/property'
import { PvrObjectEntity, ShotPointEntity } from '@/repository/ShotPointRepository'
import { FloorEntity, PropertyEntity } from '@/repository/PropertyRepository'

interface PanoramaSelect {
  floor: FloorEntity | null
  shotpoint: ShotPointEntity | null
}
export interface FacilityState {
  facilities: FacilityEntity[]
  types: FacilityTypeEntity[]
  selectedFacility: FacilityEntity | FacilityDetailEntity | null
  pvrObjectInfo: PvrObjectInfo | null
  linkedPanorama: LinkedPanoramaEntity | null
  pagination: PaginationEntity | null
  filter: FilterFacilityEntity
  listFilter: FilterFacilityEntity

  // facilitydetail cache
  panoramaSelect: PanoramaSelect
  selectedFacilityProperty: PropertyEntity | null
  requestOrigin: FacilityListRequestOrigin | null
  selectedPvrObjects: PvrObjectEntity[]

  // floor plan view and panorama view
  selectedTypeIds: number[]
}

export const state = (): FacilityState => ({
  facilities: [],
  types: [],
  selectedFacility: null,
  pvrObjectInfo: null,
  linkedPanorama: null,
  pagination: null,
  filter: {},
  listFilter: {},
  panoramaSelect: {
    floor: null,
    shotpoint: null,
  },
  selectedFacilityProperty: null,
  requestOrigin: null,
  selectedPvrObjects: [],
  selectedTypeIds: [],
})

export const getters = getterTree(state, {
  allFacilities: state => {
    return state.facilities
  },
  allTypes: state => {
    return state.types
  },
  searchFilter: state => {
    return state.filter
  },
  searchListFilter: state => {
    return state.listFilter
  },
  panoramaSelectData: state => {
    return state.panoramaSelect
  },
  currentPagination: state => {
    return state.pagination
  },
  getSelectedFacility: state => {
    return state.selectedFacility
  },
  getSelectedFacilityProperty: state => {
    return state.selectedFacilityProperty
  },
  getSelectedPvrObject: state => {
    return state.pvrObjectInfo
  },
  getSelectedLinkedPanorama: state => {
    return state.linkedPanorama
  },
  getRequestOrigin: state => {
    return state.requestOrigin
  },
  getSelectedPvrObjects: state => {
    return state.selectedPvrObjects
  },
  getSelectedTypeIds: state => {
    return state.selectedTypeIds
  },
})

export const mutations = mutationTree(state, {
  SET_Facilities(state, payload: FacilityEntity[]) {
    state.facilities = payload
  },
  ADD_Facilities(state, payload: FacilityEntity[]) {
    state.facilities.push(...payload)
  },
  UPDATE_Facility(state, facilityDetail: FacilityDetailEntity) {
    const index = state.facilities.findIndex(facility => facility.id === facilityDetail.id)
    const { attachmentFiles, linkedPanorama, ...nonDetailFacility } = facilityDetail
    if (index !== -1) {
      state.facilities.splice(index, 1, nonDetailFacility)
    }
  },
  SET_Types(state, payload: FacilityTypeEntity[]) {
    state.types = payload
  },
  SET_SelectedFacility(state, selectedFacility: FacilityEntity | FacilityDetailEntity | null) {
    state.selectedFacility = selectedFacility
  },
  SET_SelectedFacilityProperty(state, property: PropertyEntity | null) {
    state.selectedFacilityProperty = property
  },
  SET_Filter(state, filter: FilterFacilityEntity) {
    state.filter = filter
  },
  SET_ListFilter(state, filter: FilterFacilityEntity) {
    state.listFilter = filter
  },
  SET_SelectedPvrObject(state, pvrObjectInfo: PvrObjectInfo | null) {
    state.pvrObjectInfo = pvrObjectInfo
  },
  SET_SelectedLinkedPanorama(state, linkedPanorama: LinkedPanoramaEntity | null) {
    state.linkedPanorama = linkedPanorama
  },
  SET_Pagination(state, data: PaginationEntity) {
    state.pagination = data
  },
  SET_PanoramaSelect(state, data: PanoramaSelect) {
    state.panoramaSelect = data
  },
  SET_RequestOrigin(state, data: FacilityListRequestOrigin) {
    state.requestOrigin = data
  },
  SET_SelectedPvrObjects(state, data: PvrObjectEntity[]) {
    state.selectedPvrObjects = data
  },
  CLEAR_CACHE(state) {
    state.facilities = []
    state.selectedFacility = null
    state.pagination = null
    state.panoramaSelect = { floor: null, shotpoint: null }
    state.filter = {}
    state.requestOrigin = null
  },
  SET_SelectedTypeIds(state, payload: number[]) {
    state.selectedTypeIds = payload
  },
})

export const actions = actionTree(
  { state, mutations },
  {
    setFacilities({ commit }, facilities: FacilityEntity[]) {
      commit('SET_Facilities', facilities)
    },
    addFacilities({ commit }, facilities: FacilityEntity[]) {
      commit('ADD_Facilities', facilities)
    },
    updateFacility({ commit }, facility: FacilityDetailEntity) {
      commit('UPDATE_Facility', facility)
    },
    selectFacility({ commit }, facility: FacilityEntity | FacilityDetailEntity | null) {
      commit('SET_SelectedFacility', facility)
    },
    setTypes({ commit }, types: FacilityTypeEntity[]) {
      commit('SET_Types', types)
    },
    selectPvrObject({ commit }, pvrObject: PvrObjectInfo) {
      commit('SET_SelectedPvrObject', pvrObject)
    },
    resetSelectedPvrObject({ commit }) {
      commit('SET_SelectedPvrObject', null)
    },
    selectLinkedPanorama({ commit }, linkedPanorama: LinkedPanoramaEntity | null) {
      commit('SET_SelectedLinkedPanorama', linkedPanorama)
    },
    setFilter({ commit }, filter: FilterFacilityEntity) {
      commit('SET_Filter', filter)
    },
    setListFilter({ commit }, filter: FilterFacilityEntity) {
      commit('SET_ListFilter', filter)
    },
    resetSelectLinkedPanorama({ commit }) {
      commit('SET_SelectedLinkedPanorama', null)
    },
    setSearchPagination({ commit }, pagination: PaginationEntity) {
      commit('SET_Pagination', pagination)
    },
    setPanoramaSelectionData({ commit }, data: PanoramaSelect) {
      commit('SET_PanoramaSelect', data)
    },
    setRequestOrigin({ commit }, data: FacilityListRequestOrigin) {
      commit('SET_RequestOrigin', data)
    },
    setSelectedPvrObjects({ commit }, data: PvrObjectEntity[]) {
      commit('SET_SelectedPvrObjects', data)
    },
    cacheFacilityProperty({ commit }, data: PropertyEntity | null) {
      commit('SET_SelectedFacilityProperty', data)
    },
    clearCache({ commit }) {
      commit('CLEAR_CACHE')
    },
    setSelectedTypeIds({ commit }, statusIds: number[]) {
      commit('SET_SelectedTypeIds', statusIds)
    },
  }
)
