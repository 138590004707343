import { AxiosRequestConfig } from 'axios'
import { StaticFileApi } from '@/api/api'

export class StaticFileRepository {
  private readonly staticFileApi: StaticFileApi
  constructor(staticFileApi: StaticFileApi) {
    this.staticFileApi = staticFileApi
  }

  async getManualFile(organizationId?: number, options?: AxiosRequestConfig): Promise<string> {
    const { data } = await this.staticFileApi.getManual(organizationId, options)
    if (data?.data && data.ok) {
      return data?.data.sas_file
    } else {
      return ''
    }
  }
}
