import { mutationTree, actionTree, getterTree } from 'typed-vuex'
import { NotificationSnackBarEntity } from '@/repository/NotificationSettingRepository'

export interface NotificationState {
  notificationSnackBar: NotificationSnackBarEntity
}

export const state = (): NotificationState => ({
  notificationSnackBar: {
    isShow: false,
    message: '',
    timeout: 5000,
  },
})

export const getters = getterTree(state, {
  getNotificationSnackBar: state => {
    return state.notificationSnackBar
  },
})

export const mutations = mutationTree(state, {
  SET_Notification(state, notificationSnackBar: NotificationSnackBarEntity) {
    state.notificationSnackBar = notificationSnackBar
  },
})

export const actions = actionTree(
  { state, mutations },
  {
    setNotification({ commit }, notificationSnackBar: NotificationSnackBarEntity) {
      commit('SET_Notification', notificationSnackBar)
    },
    clearNotification({ commit }) {
      const initNotificationSnackBar = {
        isShow: false,
        message: '',
        timeout: 5000,
      }
      commit('SET_Notification', initNotificationSnackBar)
    },
  }
)
