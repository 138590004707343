import { mutationTree, actionTree, getterTree } from 'typed-vuex'

export interface SessionState {
  isHistoryBack: boolean
}

export const state = (): SessionState => ({
  isHistoryBack: false,
})

export const getters = getterTree(state, {
  isHistoryBack: state => {
    return state.isHistoryBack
  },
})

export const mutations = mutationTree(state, {
  SET_HistoryBack: (state, isHistoryBack: boolean) => {
    state.isHistoryBack = isHistoryBack
  },
})

export const actions = actionTree(
  { state, mutations },
  {
    setHistoryBack({ commit }, isHistoryBack: boolean): void {
      commit('SET_HistoryBack', isHistoryBack)
    },
  }
)
