import { AxiosRequestConfig } from 'axios'
import mime from 'mime-types'
import { AttachmentFile, Comment, CommentApi, CommentCreateRequest, CommentUpdateRequest } from '@/api/api'
import { AttachmentFileEntity, AttachmentOperationsEntity } from '@/repository/FileRepository'
import { FILE_STATUS } from '@/constants'

export interface CommentEntity {
  id: number
  body: string
  attachments?: AttachmentFileEntity[]
  createdById: number
  createdBy?: string
  createdAt: string
}

export interface CreateCommentEntity {
  body: string
  mentions?: number[]
  attachments?: AttachmentOperationsEntity
}
export interface UpdateCommentEntity {
  id: number
  body?: string
  mentions?: number[]
  attachments?: AttachmentOperationsEntity
}

export class CommentRepository {
  private readonly commentApi: CommentApi
  constructor(commentApi: CommentApi) {
    this.commentApi = commentApi
  }

  async getComments(
    resource: 'issue' | 'facility',
    resourceId: number,
    options?: AxiosRequestConfig
  ): Promise<CommentEntity[]> {
    let func
    if (resource === 'facility') {
      func = this.commentApi.getFacilityComments(resourceId, options)
    } else {
      func = this.commentApi.getIssueComments(resourceId, options)
    }
    const { data } = await func

    if (data?.data && data.ok) {
      return data.data.map((comment: Comment) => {
        const d = new Date(comment.created_at)
        let dateString = ''
        if (!Number.isNaN(d.getTime())) {
          const year = d.getFullYear()
          const month = (d.getMonth() + 1).toString().padStart(2, '0')
          const day = d.getDate().toString().padStart(2, '0')
          const hour = d.getHours().toString().padStart(2, '0')
          const minute = d.getMinutes().toString().padStart(2, '0')
          const second = d.getSeconds().toString().padStart(2, '0')
          dateString = `${year}/${month}/${day} ${hour}:${minute}:${second}`
        }

        const attachments = comment.attachment_files.map((file: AttachmentFile): AttachmentFileEntity => {
          // todo: always use sasThumbnail regardless status, move check to component
          const sasThumbnail = file.status === FILE_STATUS.COMPLETED ? file.sas_thumbnail : file.sas_file
          const mimeType = file.mime_type || mime.lookup(file.name)

          return {
            id: file.id,
            name: file.name,
            mimeType: mimeType || '',
            sasFile: file.sas_file || '',
            sasThumbnail: sasThumbnail || '',
            isProtected: !!file.is_protected,
            filePermissions: file.file_permissions,
            status: file.status,
          }
        })

        return {
          id: comment.id,
          body: comment.body,
          attachments,
          createdById: comment.created_by,
          createdAt: dateString,
        }
      })
    } else {
      return []
    }
  }

  async createComment(resource: 'issue' | 'facility', resourceId: number, comment: CreateCommentEntity): Promise<void> {
    const request: CommentCreateRequest = {
      body: comment.body,
    }
    const attachments = comment.attachments?.createFiles
    if (attachments) {
      request.attachment_operations = {
        uuid: comment.attachments?.uuid,
        create_files: attachments.map(attachment => {
          return {
            name: attachment.name,
            path: attachment.path,
            is_protected: attachment.isProtected,
          }
        }),
      }
    }

    let func
    if (resource === 'facility') {
      func = this.commentApi.createFacilityComment(resourceId, request)
    } else {
      func = this.commentApi.createIssueComment(resourceId, request)
    }
    const { data } = await func
    if (!data?.data || !data.ok) {
      throw new Error('API Error')
    }
  }

  async updateComment(resource: 'issue' | 'facility', resourceId: number, comment: UpdateCommentEntity): Promise<void> {
    const request: CommentUpdateRequest = {
      body: comment.body,
    }
    const deletedFiles = comment.attachments?.deleteFiles
    if (deletedFiles?.length) {
      request.attachment_operations = {
        delete_files: deletedFiles,
      }
    }
    const createdFiles = comment.attachments?.createFiles
    if (createdFiles?.length && comment.attachments?.uuid) {
      request.attachment_operations = {
        ...request.attachment_operations,
        uuid: comment.attachments.uuid,
        create_files: createdFiles.map(attachment => {
          return {
            name: attachment.name,
            path: attachment.path,
            is_protected: attachment.isProtected,
          }
        }),
      }
    }
    let func
    if (resource === 'facility') {
      func = this.commentApi.patchFacilityComment(resourceId, comment.id, request)
    } else {
      func = this.commentApi.updateIssueComment(resourceId, comment.id, request)
    }
    const { data } = await func
    if (!data?.data || !data.ok) {
      throw new Error('API Error')
    }
  }

  async deleteComment(resource: 'issue' | 'facility', resourceId: number, commentId: number): Promise<void> {
    let func
    if (resource === 'facility') {
      throw new Error('Facility Delete Comment not Implemented')
    } else {
      func = this.commentApi.deleteIssueComment(resourceId, commentId)
    }
    const { data } = await func
    if (!data.ok) {
      throw new Error('API Error')
    }
  }
}
