











































































































































import Vue from 'vue'

const SFR_ORGANIZATION_ID = 92

export default Vue.extend({
  name: 'PageHeader',
  props: {
    pageName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isSFR: false,
    }
  },
  computed: {
    getAvatarIconUrl(): string {
      return this.$accessor.user.loginUser?.iconUrl || ''
    },
    getUserName(): string {
      return this.$accessor.user.loginUser?.name || ''
    },
    isSuperUser(): boolean {
      return this.$accessor.user.loginUser?.isSuperUser || false
    },
    isAdminUser(): boolean {
      // ※isSuperUserはSTEP2にて利用
      return this.isSuperUser || !!this.$accessor.user.loginUser?.isAdmin
    },
  },
  mounted() {
    this.isSFR = this.$accessor.user.loginUser?.organization?.id === SFR_ORGANIZATION_ID
  },
  methods: {
    onAccountInformation(): void {
      const url = `https://${this.$config.ACCOUNT_INFO_DOMAIN_PROPERTY_HUB}/settings/account/?is_navi_visible=0`
      window.open(url, '_blank')
    },
    onChangePassword(): void {
      const url = `https://${this.$config.ACCOUNT_INFO_DOMAIN_PROPERTY_HUB}/settings/account/password/?is_navi_visible=0`
      window.open(url, '_blank')
    },
    onUserManagement(): void {
      const url = `https://${this.$config.ACCOUNT_INFO_DOMAIN_PROPERTY_HUB}/admin/users/`
      window.open(url, '_blank')
    },
    onPrivacyPolicy(): void {
      const url = 'https://www.sensyn-robotics.com/privacypolicy'
      window.open(url, '_blank')
    },
    onTermsOfUse(): void {
      const url = 'https://www.sensyn-robotics.com/hubfs/resource/pdf/terms-of-gembaconnect.pdf'
      window.open(url, '_blank')
    },
    onClickSettingsLink(): void {
      this.$accessor.error.resetError()
      this.$router.push({ path: '/settings/' })
    },
    async onClickDocumentUsage(): Promise<void> {
      const documentUsageUrl = await this.$repositories.staticFile.getManualFile()
      window.open(documentUsageUrl, '_blank')
    },
    async onClickDocumentSFRUsage(): Promise<void> {
      const organizationId = this.$accessor.user.loginUser?.organization?.id
      const documentUsageUrl = await this.$repositories.staticFile.getManualFile(organizationId)
      window.open(documentUsageUrl, '_blank')
    },
    onClickLogout(): void {
      this.$auth.logout()
    },
  },
})
