import { mutationTree, actionTree, getterTree } from 'typed-vuex'
import { FileActionInfo, FileActionStatus } from '@/types/file'

export interface FileState {
  actionInfo: FileActionInfo
}

export const state = (): FileState => ({
  actionInfo: {
    type: 'NONE',
    status: 'NONE',
  },
})

export const getters = getterTree(state, {
  getActionInfo: state => {
    return state.actionInfo
  },
})

export const mutations = mutationTree(state, {
  SET_ActionInfo: (state, actionInfo: FileActionInfo) => {
    state.actionInfo = actionInfo
  },
  SET_ActionStatus: (state, status: FileActionStatus) => {
    state.actionInfo.status = status
  },
  SET_ActionMessage: (state, message: string) => {
    state.actionInfo.message = message
  },
})

export const actions = actionTree(
  { state, mutations },
  {
    initActionInfo({ commit }, actionInfo: FileActionInfo): void {
      commit('SET_ActionInfo', actionInfo)
    },
    resetActionInfo({ commit }): void {
      commit('SET_ActionInfo', { type: 'NONE', status: 'NONE' })
    },
    setActionStatus({ commit }, status: FileActionStatus): void {
      commit('SET_ActionStatus', status)
    },
    setActionMessage({ commit }, message: string): void {
      commit('SET_ActionMessage', message)
    },
  }
)
