import { Context } from '@nuxt/types'

export default ({ app }: Context) => {
  // 初回アクセス時、stateはnull
  if (!history.state) {
    history.replaceState({ canGoBack: false }, '')
  }

  app.router?.afterEach(() => {
    // nuxt-link,router.pushで遷移すると、vue routerによってstateがreplaceされ
    // canGoBackがundefinedになるので、trueでセットする
    if (history.state.canGoBack === undefined) {
      const state: any = Object.assign(history.state, { canGoBack: true })
      history.replaceState(state, '')
    }
  })
}
