import { Status, StatusApi } from '@/api/api'

export interface StatusEntity {
  id: number
  name: string
  backGroundColor?: string
  fontColor?: string
  group: string
}

export class StatusRepository {
  private readonly statusApi: StatusApi
  constructor(statusApi: StatusApi) {
    this.statusApi = statusApi
  }

  async getStatus(): Promise<StatusEntity[]> {
    const { data } = await this.statusApi.getStatus()
    if (data?.data && data.ok) {
      return data.data.map((v: Status) => {
        return {
          id: v.id,
          name: v.name,
          backGroundColor: v.style.background_color,
          fontColor: v.style.font_color,
          group: v.group,
        }
      })
    } else {
      return []
    }
  }
}
