import { Context } from '@nuxt/types'
import { isEmpty, isNull } from 'lodash'
import { useGtm } from '@gtm-support/vue2-gtm'

// After each route change, push google tag event
export default ({ app }: Context): void => {
  app.router?.afterEach((to: any) => {
    // Check loginUser is fetched
    const loginUser = app.$accessor.user.loginUser
    if (isNull(loginUser) || isEmpty(loginUser)) {
      return
    }

    // Prevent from sending the duplicated url to Google Analysis
    // while updating panoramas on panorama detail page
    if (to.fullPath.includes('&repl=true')) {
      return
    }

    setImmediate(() => {
      // push gtm custom event
      const gtm = useGtm()
      gtm?.trackEvent({
        event: 'nuxtRoute',
      })
    })
  })
}
