import { mutationTree, actionTree, getterTree } from 'typed-vuex'
import { IssueEntity } from '@/repository/IssueRepository'
import {
  PropertyEntity,
  PropertyDetailEntity,
  PropertyDirectoryEntity,
  FilterPropertyEntity,
  PaginationEntity,
  FloorEntity,
  FloorPlanObjectEntity,
} from '@/repository/PropertyRepository'
import { LatestPanoramaInfo } from '@/types/property'
import { PanoramaEntity, ShotPointEntity } from '@/repository/ShotPointRepository'
import { FacilityEntity } from '@/repository/FacilityRepository'

export interface PropertyState {
  selectedProperty: PropertyEntity | PropertyDetailEntity | null
  selectedFloor: FloorEntity | null
  selectedShotPoint: ShotPointEntity | null
  properties: PropertyEntity[]
  selectedDirectory: PropertyDirectoryEntity | null
  issues: IssueEntity[] | null
  facilities: FacilityEntity[] | null
  latestPanoramaInfo: LatestPanoramaInfo | null
  issuePagination: PaginationEntity | null
  facilityPagination: PaginationEntity | null
  pagination: PaginationEntity | null
  filter: FilterPropertyEntity

  selectedPanorama: PanoramaEntity | null
  panoramaHistories: PanoramaEntity[] | null
  paneShowingDetailOf: string | null
  isOpenItemListPane: boolean
  floorPlanObjects: FloorPlanObjectEntity[]
  reloadView: 'property' | 'floor' | 'shotpoint' | 'panorama' | null
}

export const state = (): PropertyState => ({
  selectedProperty: null,
  selectedFloor: null,
  selectedShotPoint: null,
  properties: [],
  selectedDirectory: null,
  issues: null,
  facilities: null,
  latestPanoramaInfo: null,
  issuePagination: null,
  facilityPagination: null,
  pagination: null,
  filter: {},

  // floorplanview | panorama view only
  paneShowingDetailOf: null,
  isOpenItemListPane: false,
  selectedPanorama: null,
  panoramaHistories: null,
  floorPlanObjects: [],
  reloadView: null,
})

export const getters = getterTree(state, {
  allProperties: state => {
    return state.properties
  },
  getSelectedProperty: state => {
    return state.selectedProperty
  },
  getSelectedFloor: state => {
    return state.selectedFloor
  },
  getSelectedShotPoint: state => {
    return state.selectedShotPoint
  },
  getSelectedDirectory: state => {
    return state.selectedDirectory
  },
  getIssues: state => {
    return state.issues
  },
  getFacilities: state => {
    return state.facilities
  },
  /**
   *
   * @deprecated
   * move to selectedShotpoint
   */
  getLatestPanoramaInfo: state => {
    return state.latestPanoramaInfo
  },
  currentIssuePagination: state => {
    return state.issuePagination
  },
  currentFacilityPagination: state => {
    return state.facilityPagination
  },
  currentPagination: state => {
    return state.pagination
  },
  searchFilter: state => {
    return state.filter
  },
  getPanorama: state => {
    return state.selectedPanorama
  },
  getPanoramaHistories: state => {
    return state.panoramaHistories
  },
  paneDetail: state => {
    return state.paneShowingDetailOf
  },
  isOpenItemListPane: state => {
    return state.isOpenItemListPane
  },
  getFloorPlanObjects: state => {
    return state.floorPlanObjects
  },
  getReload: state => {
    return state.reloadView
  },
})

export const mutations = mutationTree(state, {
  CLEAR_PROPERTY_DATA(state) {
    state.selectedProperty = null
    state.selectedFloor = null
    state.selectedShotPoint = null
    state.selectedShotPoint = null
    state.selectedPanorama = null
    state.paneShowingDetailOf = null
  },
  SET_SelectedProperty(state, property: PropertyEntity | PropertyDetailEntity | null) {
    state.selectedProperty = property
  },
  SET_SelectedFloor(state, floor: FloorEntity | null) {
    state.selectedFloor = floor
  },
  SET_SelectedShotPoint(state, shotPoint: ShotPointEntity | null) {
    state.selectedShotPoint = shotPoint
  },
  SET_Directory: (state, data: PropertyDirectoryEntity | null) => {
    state.selectedDirectory = data
  },
  SET_Properties(state, data: PropertyEntity[]) {
    state.properties = data
  },
  ADD_Properties: (state, data: PropertyEntity[]) => {
    state.properties.push(...data)
  },
  UPDATE_Property: (state, data: PropertyEntity) => {
    const index = state.properties.findIndex(x => x.id === data.id)
    let property: PropertyEntity = data
    if ('directories' in data) {
      const { directories, ...nonDetailProperty } = data as PropertyDetailEntity
      property = nonDetailProperty
    }
    if (index !== -1) {
      state.properties.splice(index, 1, property)
    }
  },
  DELETE_Property: (state, id: number) => {
    const index = state.properties.findIndex(x => x.id === id)
    if (index !== -1) {
      state.properties.splice(index, 1)
    }
  },
  SET_Issues(state, data: IssueEntity[] | null) {
    state.issues = data
  },
  SET_Facilities(state, data: FacilityEntity[] | null) {
    state.facilities = data
  },
  /**
   *
   * @deprecated
   * move to selectedShotpoint
   */
  SET_LatestPanoramaInfo(state, data: LatestPanoramaInfo | null) {
    state.latestPanoramaInfo = data
  },
  SET_IssuePagination(state, data: PaginationEntity | null) {
    state.issuePagination = data
  },
  SET_FacilityPagination(state, data: PaginationEntity | null) {
    state.facilityPagination = data
  },
  SET_Pagination(state, data: PaginationEntity) {
    state.pagination = data
  },
  SET_Panorama(state, data: PanoramaEntity | null) {
    state.selectedPanorama = data
  },
  SET_PanoramaHistories(state, data: PanoramaEntity[] | null) {
    state.panoramaHistories = data
  },
  CLEAR_CACHE(state) {
    state.selectedProperty = null
    state.properties = []
    state.issues = null
    state.facilities = null
    state.latestPanoramaInfo = null
    state.issuePagination = null
    state.facilityPagination = null
    state.pagination = null
    state.filter = {}
  },
  SET_Filter(state, filter: FilterPropertyEntity) {
    state.filter = filter
  },
  SET_PaneDetail(state, detail: string | null) {
    state.paneShowingDetailOf = detail
  },
  SET_IsOpenItemListPane(state, isOpen: boolean) {
    state.isOpenItemListPane = isOpen
  },
  SET_FloorPlanObjects(state, data: FloorPlanObjectEntity[]) {
    state.floorPlanObjects = data
  },
  SET_ReloadView(state, data: 'property' | 'floor' | 'shotpoint' | 'panorama' | null) {
    state.reloadView = data
  },
})

export const actions = actionTree(
  { state, mutations },
  {
    setProperties({ commit }, list: PropertyEntity[]): void {
      commit('SET_Properties', list)
    },
    selectProperty({ commit }, property: PropertyEntity | PropertyDetailEntity | null): void {
      commit('SET_SelectedProperty', property)
    },
    selectFloor({ commit }, floor: FloorEntity | null): void {
      commit('SET_SelectedFloor', floor)
    },
    selectShotpoint({ commit }, shotPoint: ShotPointEntity | null): void {
      commit('SET_SelectedShotPoint', shotPoint)
    },
    addProperties({ commit }, property: PropertyEntity[]): void {
      commit('ADD_Properties', property)
    },
    updateProperty({ commit }, property: PropertyEntity): void {
      commit('UPDATE_Property', property)
    },
    deleteProperty({ commit }, id: number): void {
      commit('DELETE_Property', id)
    },
    selectDirectory({ commit }, directory: PropertyDirectoryEntity): void {
      commit('SET_Directory', directory)
    },
    resetSelectedDirectory({ commit }): void {
      commit('SET_Directory', null)
    },
    setIssues({ commit }, issues: IssueEntity[] | null) {
      commit('SET_Issues', issues)
    },
    setFacilities({ commit }, facilities: FacilityEntity[] | null) {
      commit('SET_Facilities', facilities)
    },
    /**
     *
     * @deprecated
     * move to selectedShotpoint
     */
    setLatestPanoramaInfo({ commit }, info: LatestPanoramaInfo | null) {
      commit('SET_LatestPanoramaInfo', info)
    },
    setIssuePagination({ commit }, pagination: PaginationEntity | null) {
      commit('SET_IssuePagination', pagination)
    },
    setFacilityPagination({ commit }, pagination: PaginationEntity | null) {
      commit('SET_FacilityPagination', pagination)
    },
    setSearchPagination({ commit }, pagination: PaginationEntity) {
      commit('SET_Pagination', pagination)
    },
    setPanoramaHistories({ commit }, panoramaHistories: PanoramaEntity[] | null) {
      commit('SET_PanoramaHistories', panoramaHistories)
    },
    setPanorama({ commit }, panorama: PanoramaEntity | null) {
      commit('SET_Panorama', panorama)
    },
    clearCache({ commit }) {
      commit('CLEAR_CACHE')
    },
    setFilter({ commit }, filter: FilterPropertyEntity) {
      commit('SET_Filter', filter)
    },
    setPaneDetail({ commit }, detailName: string | null) {
      commit('SET_PaneDetail', detailName)
    },
    setIsOpenItemListPane({ commit }, isOpen: boolean) {
      commit('SET_IsOpenItemListPane', isOpen)
    },
    setFloorPlanObjects({ commit }, floorPlanObjects: FloorPlanObjectEntity[]) {
      commit('SET_FloorPlanObjects', floorPlanObjects)
    },
    setReloadView({ commit }, view: 'property' | 'floor' | 'shotpoint' | 'panorama' | null) {
      commit('SET_ReloadView', view)
    },
    cleanPropertyData({ commit }) {
      commit('CLEAR_PROPERTY_DATA')
    },
  }
)
