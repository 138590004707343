











import Vue from 'vue'
import Loading from '@/components/common/container/Loading.vue'

export default Vue.extend({
  components: {
    Loading,
  },
})
