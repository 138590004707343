import { mutationTree, actionTree, getterTree } from 'typed-vuex'
import {
  FilterIssueEntity,
  IssueDetailEntity,
  IssueEntity,
  LinkedPanoramaEntity,
  PaginationEntity,
} from '@/repository/IssueRepository'
import { StatusEntity } from '@/repository/StatusRepository'
import { IssueListRequestOrigin } from '@/types/issue'
import { PvrObjectInfo } from '@/types/property'
import { PvrObjectEntity, ShotPointEntity } from '@/repository/ShotPointRepository'
import { FloorEntity, PropertyEntity } from '@/repository/PropertyRepository'

interface PanoramaSelect {
  floor: FloorEntity | null
  shotpoint: ShotPointEntity | null
}
export interface IssueState {
  issues: IssueEntity[]
  statuses: StatusEntity[]
  selectedIssue: IssueEntity | IssueDetailEntity | null
  pvrObjectInfo: PvrObjectInfo | null
  linkedPanorama: LinkedPanoramaEntity | null
  pagination: PaginationEntity | null
  filter: FilterIssueEntity
  listFilter: FilterIssueEntity

  // issuedetail cache
  panoramaSelect: PanoramaSelect
  selectedIssueProperty: PropertyEntity | null
  requestOrigin: IssueListRequestOrigin | null
  selectedPvrObjects: PvrObjectEntity[]
}

export const state = (): IssueState => ({
  issues: [],
  statuses: [],
  selectedIssue: null,
  pvrObjectInfo: null,
  linkedPanorama: null,
  pagination: null,
  filter: {},
  listFilter: {},
  panoramaSelect: {
    floor: null,
    shotpoint: null,
  },
  selectedIssueProperty: null,
  requestOrigin: null,
  selectedPvrObjects: [],
})

export const getters = getterTree(state, {
  allIssues: state => {
    return state.issues
  },
  allStatus: state => {
    return state.statuses
  },
  doneStatuses: state => {
    return state.statuses.filter(status => status.group === 'done')
  },
  notDoneStatuses: state => {
    return state.statuses.filter(status => status.group !== 'done')
  },
  searchFilter: state => {
    return state.filter
  },
  searchListFilter: state => {
    return state.listFilter
  },
  panoramaSelectData: state => {
    return state.panoramaSelect
  },
  getSelectedIssue: state => {
    return state.selectedIssue
  },
  getSelectedIssueProperty: state => {
    return state.selectedIssueProperty
  },
  getSelectedPvrObject: state => {
    return state.pvrObjectInfo
  },
  getSelectedLinkedPanorama: state => {
    return state.linkedPanorama
  },
  getRequestOrigin: state => {
    return state.requestOrigin
  },
  getSelectedPvrObjects: state => {
    return state.selectedPvrObjects
  },
})

export const mutations = mutationTree(state, {
  SET_Issues(state, payload: IssueEntity[]) {
    state.issues = payload
  },
  ADD_Issues(state, payload: IssueEntity[]) {
    state.issues.push(...payload)
  },
  UPDATE_Issue(state, issueDetail: IssueDetailEntity) {
    const index = state.issues.findIndex(issue => issue.id === issueDetail.id)
    const { attachmentFiles, linkedPanorama, ...nonDetailIssue } = issueDetail
    if (index !== -1) {
      state.issues.splice(index, 1, nonDetailIssue)
    }
  },
  SET_Statuses(state, payload: StatusEntity[]) {
    state.statuses = payload
  },
  SET_SelectedIssue(state, selectedIssue: IssueDetailEntity | null) {
    state.selectedIssue = selectedIssue
  },
  SET_SelectedIssueProperty(state, property: PropertyEntity | null) {
    state.selectedIssueProperty = property
  },
  SET_Filter(state, filter: Pick<FilterIssueEntity, 'statuses' | 'page'>) {
    state.filter = filter
  },
  SET_ListFilter(state, filter: FilterIssueEntity) {
    state.listFilter = filter
  },
  SET_SelectedPvrObject(state, pvrObjectInfo: PvrObjectInfo | null) {
    state.pvrObjectInfo = pvrObjectInfo
  },
  SET_SelectedLinkedPanorama(state, linkedPanorama: LinkedPanoramaEntity | null) {
    state.linkedPanorama = linkedPanorama
  },
  SET_Pagination(state, data: PaginationEntity) {
    state.pagination = data
  },
  SET_PanoramaSelect(state, data: PanoramaSelect) {
    state.panoramaSelect = data
  },
  SET_RequestOrigin(state, data: IssueListRequestOrigin) {
    state.requestOrigin = data
  },
  SET_SelectedPvrObjects(state, data: PvrObjectEntity[]) {
    state.selectedPvrObjects = data
  },
  CLEAR_CACHE(state) {
    state.issues = []
    state.selectedIssue = null
    state.pagination = null
    state.panoramaSelect = { floor: null, shotpoint: null }
    state.filter = {}
    // state.listFilter = {}
    state.requestOrigin = null
  },
})

export const actions = actionTree(
  { state, mutations },
  {
    setIssues({ commit }, issues: IssueEntity[]) {
      commit('SET_Issues', issues)
    },
    addIssues({ commit }, issues: IssueEntity[]) {
      commit('ADD_Issues', issues)
    },
    updateIssue({ commit }, issue: IssueDetailEntity) {
      commit('UPDATE_Issue', issue)
    },
    selectIssue({ commit }, issue: IssueDetailEntity | null) {
      commit('SET_SelectedIssue', issue)
    },
    setStatuses({ commit }, statuses: StatusEntity[]) {
      commit('SET_Statuses', statuses)
    },
    selectPvrObject({ commit }, pvrObject: PvrObjectInfo) {
      commit('SET_SelectedPvrObject', pvrObject)
    },
    resetSelectedPvrObject({ commit }) {
      commit('SET_SelectedPvrObject', null)
    },
    selectLinkedPanorama({ commit }, linkedPanorama: LinkedPanoramaEntity | null) {
      commit('SET_SelectedLinkedPanorama', linkedPanorama)
    },
    setFilter({ commit }, filter: Pick<FilterIssueEntity, 'statuses' | 'page'>) {
      commit('SET_Filter', filter)
    },
    setListFilter({ commit }, filter: FilterIssueEntity) {
      commit('SET_ListFilter', filter)
    },
    resetSelectLinkedPanorama({ commit }) {
      commit('SET_SelectedLinkedPanorama', null)
    },
    setSearchPagination({ commit }, pagination: PaginationEntity) {
      commit('SET_Pagination', pagination)
    },
    setPanoramaSelectionData({ commit }, data: PanoramaSelect) {
      commit('SET_PanoramaSelect', data)
    },
    setRequestOrigin({ commit }, data: IssueListRequestOrigin) {
      commit('SET_RequestOrigin', data)
    },
    setSelectedPvrObjects({ commit }, data: PvrObjectEntity[]) {
      commit('SET_SelectedPvrObjects', data)
    },
    cacheIssueProperty({ commit }, data: PropertyEntity | null) {
      commit('SET_SelectedIssueProperty', data)
    },
    clearCache({ commit }) {
      commit('CLEAR_CACHE')
    },
  }
)
