import { FacilityType, FacilityApi } from '@/api/api'

export interface FacilityTypeEntity {
  id: number
  name: string
  backGroundColor: string
  fontColor: string
  kind: string
}

export class FacilityTypeRepository {
  private readonly facilityApi: FacilityApi
  constructor(facilityApi: FacilityApi) {
    this.facilityApi = facilityApi
  }

  async getFacilityTypes(): Promise<FacilityTypeEntity[]> {
    const { data } = await this.facilityApi.getFacilitiesTypes()
    if (data?.data && data.ok) {
      return data.data.map((v: FacilityType) => {
        return {
          id: v.id,
          name: v.name,
          backGroundColor: v.style.background_color,
          fontColor: v.style.font_color,
          kind: v.kind,
        }
      })
    } else {
      return []
    }
  }
}
