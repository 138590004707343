import { mutationTree, actionTree, getterTree } from 'typed-vuex'
import { DivisionEntity, RoleEntity, UserEntity, LoginUserEntity } from '@/repository/UserRepository'

export interface UserState {
  divisions: DivisionEntity[]
  roles: RoleEntity[]
  users: UserEntity[]
  loginUser: LoginUserEntity | null
}

export const state = (): UserState => ({
  divisions: [],
  roles: [],
  users: [],
  loginUser: null,
})

export const getters = getterTree(state, {
  allDivisions: state => {
    return state.divisions ?? []
  },
  allRoles: state => {
    return state.roles ?? []
  },
  allUsers: state => {
    return state.users ?? []
  },
  loginUser: state => {
    return state.loginUser
  },
  undeletedUsers: state => {
    return state.users.filter(user => user.isDeleted === false) ?? []
  },
  usersWithoutSRAdmin: state => {
    return state.users.filter(user => user.isSuperUser === false) ?? []
  },
})

export const mutations = mutationTree(state, {
  SET_Divisions(state, payload) {
    state.divisions = payload
  },
  SET_Roles(state, payload) {
    state.roles = payload
  },
  SET_Users(state, payload) {
    state.users = payload
  },
  SET_LoginUser(state, payload: LoginUserEntity | null) {
    state.loginUser = payload
  },
})

export const actions = actionTree(
  { state, mutations },
  {
    setDivisions({ commit }, divisions: DivisionEntity[]): void {
      commit('SET_Divisions', divisions)
    },
    setRoles({ commit }, roles: RoleEntity[]): void {
      commit('SET_Roles', roles)
    },
    setUsers({ commit }, users: UserEntity[]): void {
      commit('SET_Users', users)
    },
    setLoginUser({ commit }, loginUser: LoginUserEntity | null): void {
      commit('SET_LoginUser', loginUser)
    },
  }
)
