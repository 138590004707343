import { AxiosRequestConfig } from 'axios'
import mime from 'mime-types'
import {
  Facility,
  FacilityApi,
  FacilityCreateRequest,
  FacilityPatchRequest,
  AttachmentFile,
  VrCoordinates,
  FloorFacility,
  PublicApi,
} from '@/api/api'
import { AttachmentOperationsEntity, FileCreateRequestEntity, AttachmentFileEntity } from '@/repository/FileRepository'
import { CommentEntity } from '@/repository/CommentRepository'
import { LinkPanoramaCreateEntity, LinkPanoramaUpdateEntity, PanoramaEntity } from '@/repository/ShotPointRepository'
import { toCamelCase, toSnakeCase } from '@/utils/common'
import { FILE_STATUS } from '@/constants'
import { Coordinate } from '@/types/property'

export interface LinkFloorPlanUpdateRequestLinkEntity {
  floorPlanId: number
  coordinate: Coordinate
}

export interface LinkFloorPlanUpdateRequestPatchEntity {
  coordinate: Coordinate
}
export interface LinkFloorPlanCreateEntity {
  link: LinkFloorPlanUpdateRequestLinkEntity
}
export interface CreateFacilityEntity {
  facilityCode: string
  title: string
  type: number
  description: string
  propertyId: number
  floorId: number | null
  files?: File[]
  attachmentOperations?: AttachmentOperationsEntity
  linkedPanoramaOperation?: LinkPanoramaCreateEntity
  linkedFloorPlanOperation?: LinkFloorPlanCreateEntity
}

export interface PaginationEntity {
  page: number
  perPage: number
  totalCount: number
}

export interface LinkFloorPlanUpdateEntity {
  link?: LinkFloorPlanUpdateRequestLinkEntity
  patch?: LinkFloorPlanUpdateRequestPatchEntity
  unlink?: boolean
}

export interface UpdateFacilityEntity {
  facilityId?: number
  title?: string
  type?: number
  description?: string
  files?: (File | AttachmentFileEntity)[]
  propertyId?: number
  floorId?: number | null
  attachmentOperations?: AttachmentOperationsEntity
  linkedPanoramaOperation?: LinkPanoramaUpdateEntity
  linkedFloorPlanOperation?: LinkFloorPlanUpdateEntity
}

export interface LinkedPanoramaEntity {
  latestPanorama: PanoramaEntity
  pvrObjectId: number
  shotPointId: number
  vrCoordinates: VrCoordinates
}

export interface LinkedFloorPlanEntity {
  floorPlanId: number
  floorPlanObjectId: number
  coordinate: Coordinate
}

export interface facilityUpdatedStatusEntity {
  facilityId: number
  updatedAt: string
}

export interface FacilityEntity extends CreateFacilityEntity {
  id: number
  facilityCode: string
  createdBy: number
  createdAt: string
  updatedAt: string
  propertyName?: string
  floorName?: string
  userPermissions: string[]
  linkedShotPoint?: {
    id: number
  }
  facilityUpdatedStatus?: facilityUpdatedStatusEntity
}

export interface FacilityDetailEntity extends FacilityEntity {
  attachmentFiles?: AttachmentFileEntity[]
  comments?: CommentEntity[]
  linkedPanorama?: LinkedPanoramaEntity
  linkedFloorPlan?: LinkedFloorPlanEntity
}

export interface PublicFacilityEntity {
  facilityCode: string
}

export interface FacilityEntities {
  facilities: FacilityEntity[]
  pagination: PaginationEntity
}

export interface FilterFacilityEntity {
  title?: string
  property?: number
  types?: number[]
  page?: number
  perPage?: number
}

export interface ReferredPropertyCreateRequest {
  propertyId: number
  floorId?: number
}

export interface FacilityCreateRequestAttributes {
  referredProperty: ReferredPropertyCreateRequest
}

export class FacilityRepository {
  private readonly facilityApi: FacilityApi
  private readonly publicApi: PublicApi
  constructor(facilityApi: FacilityApi, publicApi: PublicApi) {
    this.facilityApi = facilityApi
    this.publicApi = publicApi
  }

  async searchFacilities(params?: FilterFacilityEntity, options?: AxiosRequestConfig): Promise<FacilityEntities> {
    const { data } = await this.facilityApi.getFacilities(
      params?.title,
      params?.property,
      params?.types,
      params?.page,
      params?.perPage,
      options
    )
    if (data?.data && data.ok) {
      const facilitiesResponse = {
        facilities: data.data.map((facility: Facility): FacilityEntity => {
          return {
            ...toCamelCase(facility),
            floorId: facility.attributes.referred_property?.floor_id,
            floorName: facility.attributes.referred_property?.floor_name || '',
            propertyId: facility.attributes.referred_property.property_id,
            propertyName: facility.attributes.referred_property.property_name,
          }
        }),
        pagination: { ...toCamelCase(data.pagination) },
      }
      return facilitiesResponse
    } else {
      throw new Error('API Error')
    }
  }

  async fetchFloorFacilities(
    propertyId: number,
    floorId: number,
    type?: number[],
    options?: AxiosRequestConfig
  ): Promise<FacilityEntity[]> {
    const { data } = await this.facilityApi.getFacilitiesInFloor(
      propertyId.toString(),
      floorId.toString(),
      type,
      options
    )
    if (data?.data && data.ok) {
      const facilitiesResponse = data.data.map((facility: FloorFacility): FacilityEntity => {
        return {
          ...toCamelCase(facility),
          floorId: facility.attributes.referred_property?.floor_id,
          floorName: facility.attributes.referred_property?.floor_name || '',
          propertyId: facility.attributes.referred_property.property_id,
          propertyName: facility.attributes.referred_property.property_name,
        }
      })
      return facilitiesResponse
    }

    throw new Error('API Error')
  }

  async getFacility(id: number, options?: AxiosRequestConfig): Promise<FacilityDetailEntity | null> {
    const { data } = await this.facilityApi.getFacility(id, options)
    if (data?.data && data.ok) {
      const facility = data.data
      const attachmentFiles = await this.attachmentEntityCreator(facility.attachment_files)
      return {
        ...toCamelCase(facility),
        floorId: facility.attributes.referred_property?.floor_id,
        floorName: facility.attributes.referred_property?.floor_name || '',
        propertyId: facility.attributes.referred_property.property_id,
        propertyName: facility.attributes.referred_property.property_name,
        attachmentFiles,
      }
    } else {
      return null
    }
  }

  async getPublicFacility(id: number, options?: AxiosRequestConfig): Promise<PublicFacilityEntity | null> {
    const { data } = await this.publicApi.getFacilityById(id, options)
    if (data?.data && data.ok) {
      const facility = data.data
      return {
        ...toCamelCase(facility),
      }
    } else {
      return null
    }
  }

  async deleteFacility(id: number): Promise<boolean> {
    try {
      const { data } = await this.facilityApi.deleteFacility(id)
      if (!data.ok) {
        throw new Error('API Error')
      }
      return true
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async registerFacility(facility: CreateFacilityEntity): Promise<FacilityDetailEntity> {
    try {
      const request: FacilityCreateRequest = {
        title: facility.title,
        type: facility.type,
        description: facility.description,
        attributes: {
          referred_property: {
            property_id: facility.propertyId,
          },
        },
      }
      if (facility.floorId) {
        request.attributes.referred_property.floor_id = facility.floorId
      }
      if (
        facility.attachmentOperations &&
        facility.attachmentOperations.createFiles &&
        facility.attachmentOperations.createFiles.length !== 0
      ) {
        request.attachment_operations = {
          uuid: facility.attachmentOperations.uuid,
          create_files: facility.attachmentOperations.createFiles?.map((createFile: FileCreateRequestEntity) => {
            return {
              name: createFile.name,
              path: createFile.path,
              is_protected: createFile.isProtected,
            }
          }),
        }
      }
      if (facility.linkedPanoramaOperation?.link) {
        request.linked_panorama_operation = {
          link: {
            shot_point_id: facility.linkedPanoramaOperation.link.shotPointId,
            vr_coordinates: facility.linkedPanoramaOperation.link.vrCoordinates,
          },
        }
      }
      if (facility.linkedFloorPlanOperation?.link) {
        request.linked_floor_plan_operation = {
          link: {
            floor_plan_id: facility.linkedFloorPlanOperation.link.floorPlanId,
            coordinate: facility.linkedFloorPlanOperation.link.coordinate,
          },
        }
      }
      const { data } = await this.facilityApi.createFacility(request)
      const createdFacility = data?.data
      if (!createdFacility || !data.ok) {
        throw new Error('API Error')
      }
      const attachmentFiles = await this.attachmentEntityCreator(createdFacility.attachment_files)
      return {
        ...toCamelCase(createdFacility),
        floorId: createdFacility.attributes.referred_property?.floor_id,
        floorName: createdFacility.attributes.referred_property?.floor_name || '',
        propertyId: createdFacility.attributes.referred_property.property_id,
        propertyName: createdFacility.attributes.referred_property.property_name,
        attachmentFiles,
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async updateFacility(facility: UpdateFacilityEntity, facilityId: number): Promise<FacilityDetailEntity> {
    const request: FacilityPatchRequest = {}
    if (typeof facility.title !== 'undefined') {
      request.title = facility.title
    }
    if (typeof facility.type !== 'undefined') {
      request.type = facility.type
    }
    if (typeof facility.description !== 'undefined') {
      request.description = facility.description
    }

    if (facility.linkedPanoramaOperation) {
      request.linked_panorama_operation = toSnakeCase(facility.linkedPanoramaOperation)
    }
    if (facility.linkedFloorPlanOperation) {
      request.linked_floor_plan_operation = toSnakeCase(facility.linkedFloorPlanOperation)
    }

    if (facility.attachmentOperations) {
      if (facility.attachmentOperations.createFiles && facility.attachmentOperations.createFiles.length !== 0) {
        request.attachment_operations = {
          uuid: facility.attachmentOperations.uuid,
          create_files: facility.attachmentOperations.createFiles?.map((createFile: FileCreateRequestEntity) => {
            return {
              name: createFile.name,
              path: createFile.path,
              is_protected: createFile.isProtected,
            }
          }),
        }
      }
      // ファイル削除
      if (facility.attachmentOperations.deleteFiles && facility.attachmentOperations.deleteFiles.length !== 0) {
        request.attachment_operations = Object.assign(request.attachment_operations ?? {}, {
          delete_files: facility.attachmentOperations.deleteFiles,
        })
      }
    }

    if (facility.propertyId) {
      request.attributes = {
        referred_property: {
          property_id: facility.propertyId,
          floor_id: facility.floorId as number | null,
        },
      }
      if (facility.floorId && request.attributes.referred_property) {
        request.attributes.referred_property.floor_id = facility.floorId
      }
    }
    const { data } = await this.facilityApi.patchFacility(facilityId, request)
    if (!data?.data || !data.ok) {
      throw new Error('API Error')
    }
    const updatedFacility = data.data
    const attachmentFiles = await this.attachmentEntityCreator(updatedFacility.attachment_files)
    return {
      ...toCamelCase(updatedFacility),
      floorId: updatedFacility.attributes.referred_property?.floor_id,
      floorName: updatedFacility.attributes.referred_property?.floor_name || '',
      propertyId: updatedFacility.attributes.referred_property.property_id,
      propertyName: updatedFacility.attributes.referred_property.property_name,
      attachmentFiles,
    }
  }

  attachmentEntityCreator(attachments: AttachmentFile[]): AttachmentFileEntity[] {
    return attachments.map((file: AttachmentFile): AttachmentFileEntity => {
      // todo: always use sasThumbnail regardless status, move check to component
      const sasThumbnail = file.status === FILE_STATUS.COMPLETED ? file.sas_thumbnail : file.sas_file
      const mimeType = file.mime_type || mime.lookup(file.name)

      return {
        id: file.id,
        name: file.name,
        mimeType: mimeType || '',
        sasFile: file.sas_file || '',
        sasThumbnail: sasThumbnail || '',
        isProtected: !!file.is_protected,
        filePermissions: file.file_permissions,
        status: file.status,
      }
    })
  }
}
