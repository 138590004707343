import {
  PropertyDirectoryMasterApi,
  PropertyDirectoryMaster,
  PropertyDirectoryMasterCreateRequest,
  PropertyDirectoryMasterUpdateRequest,
  PropertyDirectoryMasterBulkUpdateRequest,
} from '@/api/api'
import { toCamelCase, toSnakeCase } from '@/utils/common'

export interface CreatePropertyDirectoryMasterEntity {
  name: string
  path: string
  isProtected: boolean
}

export interface PropertyDirectoryMasterEntity {
  id: number
  name: string
  path: string
  isProtected: boolean
  order: number
  parentId: number | null
}

export interface BulkUpdatePropertyDirectoryMasterEntity {
  id: number
  order: number
  path: string
  isProtected: boolean
}

export class PropertyDirectoryMasterRepository {
  private readonly propertyDirectoryMasterApi: PropertyDirectoryMasterApi

  constructor(propertyDirectoryMasterApi: PropertyDirectoryMasterApi) {
    this.propertyDirectoryMasterApi = propertyDirectoryMasterApi
  }

  async getAllDirectoryMasters(): Promise<PropertyDirectoryMasterEntity[]> {
    const { data } = await this.propertyDirectoryMasterApi.listPropertyDirectoryMasters()
    if (data?.data && data.ok) {
      return data.data.map((v: PropertyDirectoryMaster): PropertyDirectoryMasterEntity => toCamelCase(v))
    } else {
      throw new Error('API Error')
    }
  }

  async createDirectoryMaster(entity: CreatePropertyDirectoryMasterEntity): Promise<PropertyDirectoryMasterEntity> {
    const request: PropertyDirectoryMasterCreateRequest = toSnakeCase(entity)
    const { data } = await this.propertyDirectoryMasterApi.createPropertyDirectoryMaster(request)

    if (data?.data && data.ok) {
      return toCamelCase(data.data)
    } else {
      throw new Error('API Error')
    }
  }

  async updateDirectoryMaster(entity: PropertyDirectoryMasterEntity): Promise<PropertyDirectoryMasterEntity> {
    const request: PropertyDirectoryMasterUpdateRequest = {
      name: entity.name,
      is_protected: entity.isProtected,
    }
    const { data } = await this.propertyDirectoryMasterApi.updatePropertyDirectoryMaster(entity.id, request)

    if (data?.data && data.ok) {
      return toCamelCase(data.data)
    } else {
      throw new Error('API Error')
    }
  }

  async bulkUpdateDirectoryMasters(
    entities: BulkUpdatePropertyDirectoryMasterEntity[]
  ): Promise<PropertyDirectoryMasterEntity[]> {
    const directoryMasters = entities.map((v: BulkUpdatePropertyDirectoryMasterEntity) => toSnakeCase(v))
    const request: PropertyDirectoryMasterBulkUpdateRequest = {
      directory_masters: directoryMasters,
    }
    const { data } = await this.propertyDirectoryMasterApi.bulkUpdatePropertyDirectoryMaster(request)

    if (data?.data && data.ok) {
      return data.data.map((v: PropertyDirectoryMaster) => toCamelCase(v))
    } else {
      throw new Error('API Error')
    }
  }

  async deleteDirectoryMaster(id: number): Promise<boolean> {
    try {
      const { data } = await this.propertyDirectoryMasterApi.deletePropertyDirectoryMaster(id)
      if (!data.ok) {
        throw new Error('API Error')
      }
      return true
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
