import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import 'dayjs/locale/en'
import 'dayjs/locale/ja'

export default ({ app }) => {
  app.$dayjs.extend(utc)
  app.$dayjs.extend(timezone)
  app.$dayjs.extend(localizedFormat)
  app.$dayjs.extend(isToday)
  app.$dayjs.extend(isYesterday)
  app.$dayjs.locale(app.i18n.locale)
  app.$dayjs.tz.setDefault('Asia/Tokyo')
  app.i18n.beforeLanguageSwitch = async (oldLocale, newLocale) => {
    await app.$dayjs.locale(newLocale)
  }
}
