import { getAccessorType } from 'typed-vuex'
import * as issue from '@/store/issue'
import * as facility from '@/store/facility'
import * as loading from '@/store/loading'
import * as notification from '@/store/notification'
import * as property from '@/store/property'
import * as user from '@/store/user'
import * as file from '@/store/file'
import * as setting from '@/store/setting'
import * as error from '@/store/error'
import * as session from '@/store/session'
import * as dialog from '@/store/dialog'

export const state = () => ({})
export const actions = {}
export const mutations = {}
export const getters = {}

export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
  modules: {
    issue,
    facility,
    loading,
    notification,
    property,
    user,
    file,
    setting,
    error,
    session,
    dialog,
  },
})
