import { mutationTree, actionTree, getterTree } from 'typed-vuex'

export interface DialogState {
  isOpen: boolean
}

export const state = (): DialogState => ({
  isOpen: false,
})

export const getters = getterTree(state, {
  isOpen: state => {
    return state.isOpen
  },
})

export const mutations = mutationTree(state, {
  SET_DialogState: (state, isOpen: boolean) => {
    state.isOpen = isOpen
  },
})

export const actions = actionTree(
  { state, mutations },
  {
    setDialog({ commit }, isOpen: boolean): void {
      commit('SET_DialogState', isOpen)
    },
  }
)
