var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","permanent":"","temporary":_vm.isNarrowWindowWidth && !_vm.miniVariant,"hide-overlay":"","mini-variant":_vm.miniVariant,"width":"248"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"disabled":!_vm.miniVariant,"color":"#000000cc","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"item collapse-expand",on:{"click":_vm.onClickExpand}},'v-list-item',attrs,false),on),[(_vm.miniVariant)?_c('v-list-item-icon',{staticClass:"item-icon"},[_c('v-icon',{staticClass:"icon"},[_vm._v(_vm._s('mdi-menu'))])],1):_c('v-list-item-icon',{staticClass:"item-icon"},[_c('v-icon',{staticClass:"icon"},[_vm._v(_vm._s('mdi-chevron-left'))])],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('menu.close')))])],1)]}}])},[_vm._v("\n      "+_vm._s(_vm.$t('menu.open'))+"\n    ")])]},proxy:true}])},[_c('v-list-item',{staticClass:"product-logo"},[_c('v-list-item-icon',{staticClass:"product-icon"},[_c('img',{attrs:{"src":"/icons/app_icon.svg"}})]),_vm._v(" "),_c('v-list-item-title',{staticClass:"product-text"},[_vm._v(_vm._s(_vm.$t('product-name')))])],1),_vm._v(" "),_c('v-list',{attrs:{"flat":""}},[_c('v-tooltip',{attrs:{"disabled":!_vm.miniVariant,"color":"#000000cc","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"item",attrs:{"link":"","to":"/properties/","nuxt":"","active-class":"selected"},nativeOn:{"!click":function($event){$event.preventDefault();return _vm.onClickPropertiesLink.apply(null, arguments)}}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"item-icon"},[_c('span',{staticClass:"svg-icon property-icon"})]),_vm._v(" "),_c('v-list-item-title',{staticClass:"item-text"},[_vm._v(_vm._s(_vm.$t('menu.property')))])],1)]}}])},[_vm._v("\n      "+_vm._s(_vm.$t('menu.property'))+"\n    ")]),_vm._v(" "),_c('v-tooltip',{attrs:{"disabled":!_vm.miniVariant,"color":"#000000cc","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"item",attrs:{"link":"","to":"/issues/","nuxt":"","active-class":"selected"},nativeOn:{"!click":function($event){$event.preventDefault();return _vm.onClickIssuesLink.apply(null, arguments)}}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"item-icon"},[_c('v-icon',{staticClass:"mdi-icon"},[_vm._v(_vm._s('mdi-clipboard-text-outline'))])],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"item-text"},[_vm._v(_vm._s(_vm.$t('menu.issue')))])],1)]}}])},[_vm._v("\n      "+_vm._s(_vm.$t('menu.issue'))+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }