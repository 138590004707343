import { BlockBlobClient } from '@azure/storage-blob'
import { AxiosRequestConfig } from 'axios'
import mime from 'mime-types'
import {
  AttachmentFile,
  FileApi,
  FloorApi,
  FloorIssue,
  FloorPlanObject,
  FloorPlansPatchRequest,
  Property,
  PropertyApi,
  PropertyCreateRequest,
  PropertyDirectoryBulkOperationsRequest,
  PropertySimple,
  PropertyUpdateRequest,
  PublicApi,
} from '@/api/api'
import { FILE_STATUS } from '@/constants'
import { BulkFileOperationEntity } from '@/repository/FileRepository'
import { IssueEntity } from '@/repository/IssueRepository'
import { PvrObjectContentEntity, PvrObjectContentType, ShotPointEntity } from '@/repository/ShotPointRepository'
import { toCamelCase } from '@/utils/common'

export type FloorplanModeType = 'view' | 'edit'

export interface AttachmentFileEntity {
  id: number
  name: string
  mimeType: string
  size: number
  userId: number
  contentCreatedAt: string
  createdAt: string
  status: number
  sasFile: string
  sasThumbnail: string
  isProtected: boolean
  filePermissions: string[]
}
export interface CreateFloorEntity {
  name: string
  order: number
}

export interface UpdateFloorEntity extends CreateFloorEntity {
  id: number
}

export interface FloorOperations {
  createFloors?: CreateFloorEntity[]
  updateFloors?: UpdateFloorEntity[]
  deleteFloors?: number[]
}

export interface PatchFloorEntity {
  id: number
  path: string
}

export interface FloorPlanEntity {
  // TODO : need other props ??
  id: number
  sasFile: string
  status: number
}

export interface FloorPlanObjectEntity {
  id: number
  title: string
  description: string
  category: number
  latitude: number
  longitude: number
  type: string
  resourceId: string
}

export interface FloorPlanIssueEntity {
  id: number
  content: PvrObjectContentEntity
  issue: IssueEntity
}

export interface FloorEntity {
  id: number
  name: string
  order: number
  floorPlan?: FloorPlanEntity
  issueCount?: number
  facilityCount?: number
  shotPointsCount?: number
  shotPoints?: ShotPointEntity[]
}

export interface CreatePropertyEntity {
  name: string
  nameKana: string
  zipCode: string
  address: string
  aboveGroundFloors: number
  underGroundFloors: number
  description: string
  assignees: number[]
  accessibleDivisions: number[]
  floors: CreateFloorEntity[]
  completionDate?: string
  floorPlan?: any
  imageFile?: File // 保存用
}

export interface propertyUpdatedStatusEntity {
  lastUpdatedItem: string
  propertyId: number
  updatedAt: string
}

export interface SimplePropertyEntity {
  id: number
  name: string
  nameKana: string
  floors: FloorEntity[]
  assignees: number[]
  accessibleDivisions: number[]
}
export interface PropertyEntity extends SimplePropertyEntity {
  zipCode: string
  prefecture: string
  address: string
  completionDate: string | null
  aboveGroundFloors: number
  underGroundFloors: number
  description: string
  floorPlan?: any
  image: AttachmentFileEntity | null // 表示用
  imageFile?: File | null // 保存用
  propertyUpdatedStatus?: propertyUpdatedStatusEntity | null
}

export interface PaginationEntity {
  page: number
  perPage: number
  totalCount: number
}

export interface PropertyEntities {
  properties: PropertyEntity[]
  pagination: PaginationEntity
}

export interface PublicPropertyEntity {
  name: string
}

export interface UpdatePropertyEntity {
  name?: string
  nameKana?: string
  zipCode?: string
  address?: string
  completionDate?: string
  aboveGroundFloors?: number
  underGroundFloors?: number
  description?: string
  assignees?: number[]
  accessibleDivisions?: number[]
  floorOperations?: FloorOperations
  imageFile?: File | null // 保存用
}

export interface PropertyDirectoryEntity {
  id: number
  name: string
  isProtected: boolean
  order: number
  path: string
  masterId: number
  propertyId: number
  userPermissions: string[]
  parentId?: number | null
  parent?: PropertyDirectoryEntity | null
  children?: PropertyDirectoryEntity[]
  attachmentFiles?: AttachmentFileEntity[]
}
export interface PropertyDetailEntity extends PropertyEntity {
  floorPlan: boolean
  directories: PropertyDirectoryEntity[]
}

export interface FilterPropertyEntity {
  title?: string
  assignees?: number[]
  isAssignedMe?: boolean
  orderBy?: string
  page?: number
  perPage?: number
}

export function initFilterProperty(options?: Partial<FilterPropertyEntity> | null): FilterPropertyEntity {
  return {
    ...options,
    perPage: 20,
  }
}

export class PropertyRepository {
  private readonly propertyApi: PropertyApi
  private readonly floorApi: FloorApi
  private readonly fileApi: FileApi
  private readonly publicApi: PublicApi

  constructor(propertyApi: PropertyApi, floorApi: FloorApi, fileApi: FileApi, publicApi: PublicApi) {
    this.propertyApi = propertyApi
    this.floorApi = floorApi
    this.fileApi = fileApi
    this.publicApi = publicApi
  }

  async fetchAllSimple(options?: AxiosRequestConfig): Promise<SimplePropertyEntity[]> {
    const { data } = await this.propertyApi.getPropertiesList(options)
    if (data?.data && data.ok) {
      return data.data.map((property: PropertySimple): SimplePropertyEntity => {
        return {
          ...toCamelCase(property),
        }
      })
    } else {
      throw new Error('API Error')
    }
  }

  async searchProperties(params?: FilterPropertyEntity, options?: AxiosRequestConfig): Promise<PropertyEntities> {
    const { data } = await this.propertyApi.searchProperties(
      params?.title,
      params?.assignees,
      params?.isAssignedMe,
      params?.orderBy,
      params?.page,
      params?.perPage,
      options
    )
    if (data?.data && data.ok) {
      const propertyResponse = {
        properties: data.data.map((property: Property): PropertyEntity => {
          return {
            ...toCamelCase(property),
            prefecture: '',
            address: property.address,
            completionDate: property.completion_date || '',
            aboveGroundFloors: property.above_ground_floors,
            underGroundFloors: property.under_ground_floors,
            description: property.description,
            assignees: property.assignees,
            accessibleDivisions: property.accessible_divisions ? property.accessible_divisions : [],
            floors: property.floors.map(floor => {
              return {
                id: floor.id,
                name: floor.name,
                order: floor.order,
                floorPlan: floor.floor_plan || null,
                shotPointsCount: floor.shot_points_count || 0,
                shotPoints: [],
              }
            }),
            floorPlan: property.floors.some(f => f.floor_plan),
            image: toCamelCase(property.image),
          }
        }),
        pagination: { ...toCamelCase(data.pagination) },
      }
      return propertyResponse
    } else {
      throw new Error('API Error')
    }
  }

  async getProperty(id: number, options?: AxiosRequestConfig): Promise<PropertyDetailEntity | null> {
    const { data } = await this.propertyApi.getProperty(id, options)
    if (data?.data && data.ok) {
      const property = data.data
      let directories = [] as PropertyDirectoryEntity[]
      if (property.directories) {
        directories = property.directories.map((v: any) => {
          return toCamelCase(v)
        })
      }

      return {
        id: property.id,
        name: property.name,
        nameKana: property.name_kana,
        zipCode: property.zip_code,
        prefecture: '',
        address: property.address,
        completionDate: property.completion_date,
        aboveGroundFloors: property.above_ground_floors,
        underGroundFloors: property.under_ground_floors,
        description: property.description,
        assignees: property.assignees,
        accessibleDivisions: property.accessible_divisions ?? [],
        floors: property.floors.map((v: any) => {
          return toCamelCase(v)
        }),
        floorPlan: property.floors.some(f => f.floor_plan),
        directories,
        image: toCamelCase(property.image),
        propertyUpdatedStatus: toCamelCase(property.property_updated_status),
      }
    } else {
      return null
    }
  }

  async getPublicProperty(id: number, options?: AxiosRequestConfig): Promise<PublicPropertyEntity | null> {
    const { data } = await this.publicApi.getPropertyById(id, options)
    if (data?.data && data.ok) {
      const property = data.data
      return {
        ...toCamelCase(property),
      }
    } else {
      return null
    }
  }

  async registerProperty(property: CreatePropertyEntity): Promise<PropertyEntity> {
    let image
    if (property.imageFile) {
      image = {}
      image = Object.assign(image, {
        name: property.imageFile.name,
      })
      image = Object.assign(image, {
        path: await this.uploadPropertyImage(property.imageFile),
      })
    }
    const request: PropertyCreateRequest = {
      name: property.name,
      name_kana: property.nameKana,
      zip_code: property.zipCode,
      address: property.address,
      completion_date: property.completionDate ? property.completionDate + ' 00:00:00Z' : '',
      description: property.description,
      above_ground_floors: property.aboveGroundFloors,
      under_ground_floors: property.underGroundFloors,
      floor_operations: {
        create_floors: property.floors.map(floor => {
          return {
            name: floor.name,
            order: floor.order,
          }
        }),
      },
      assignees: property.assignees,
      accessible_divisions: property.accessibleDivisions,
      image,
    }

    const { data } = await this.propertyApi.createProperty(request)
    if (!data?.data || !data.ok) {
      throw new Error('API Error')
    }
    const createdProperty = data.data
    return {
      ...toCamelCase(createdProperty),
      prefecture: '',
      address: createdProperty.address,
      completionDate: createdProperty.completion_date || '',
      aboveGroundFloors: createdProperty.above_ground_floors,
      underGroundFloors: createdProperty.under_ground_floors,
      description: createdProperty.description,
      assignees: createdProperty.assignees ? createdProperty.assignees : [],
      accessibleDivisions: createdProperty.accessible_divisions ? createdProperty.accessible_divisions : [],
      floors: createdProperty.floors.map(floor => {
        return {
          id: floor.id,
          name: floor.name,
          order: floor.order,
          floorPlan: floor.floor_plan || null,
          shotPointsCount: floor.shot_points_count || 0,
          shotPoints: [],
        }
      }),
      floorPlan: createdProperty.floors.some(f => f.floor_plan),
      image: createdProperty.image === null ? null : { sasFile: createdProperty.image?.sas_file },
    }
  }

  async updateProperty(id: number, property: UpdatePropertyEntity): Promise<PropertyEntity> {
    let image
    if (property.imageFile !== undefined) {
      image = {}
      if (property.imageFile === null) {
        image = {
          name: '',
          path: null,
        }
      } else {
        image = Object.assign(image, {
          name: property.imageFile.name,
          path: await this.uploadPropertyImage(property.imageFile),
        })
      }
    }
    const request: PropertyUpdateRequest = {
      name: property.name,
      name_kana: property.nameKana,
      zip_code: property.zipCode,
      address: property.address,
      completion_date: property.completionDate,
      description: property.description,
      above_ground_floors: property.aboveGroundFloors,
      under_ground_floors: property.underGroundFloors,
      floor_operations: {
        create_floors: property.floorOperations?.createFloors,
        update_floors: property.floorOperations?.updateFloors,
        delete_floors: property.floorOperations?.deleteFloors,
      },
      assignees: property.assignees,
      accessible_divisions: property.accessibleDivisions,
      image,
    }

    const { data } = await this.propertyApi.updateProperty(id, request)
    if (!data?.data || !data.ok) {
      throw new Error('API Error')
    }
    const updatedProperty = data.data
    return {
      ...toCamelCase(updatedProperty),
      prefecture: '',
      address: updatedProperty.address,
      completionDate: updatedProperty.completion_date || '',
      aboveGroundFloors: updatedProperty.above_ground_floors,
      underGroundFloors: updatedProperty.under_ground_floors,
      description: updatedProperty.description,
      assignees: updatedProperty.assignees ? updatedProperty.assignees : [],
      accessibleDivisions: updatedProperty.accessible_divisions ? updatedProperty.accessible_divisions : [],
      floors: updatedProperty.floors.map(floor => {
        return {
          id: floor.id,
          name: floor.name,
          order: floor.order,
          floorPlan: floor.floor_plan || null,
          shotPointsCount: floor.shot_points_count || 0,
          shotPoints: [],
        }
      }),
      floorPlan: updatedProperty.floors.some(f => f.floor_plan),
      image: updatedProperty.image === null ? null : { sasFile: updatedProperty.image?.sas_file },
    }
  }

  async deleteProperty(id: number): Promise<void> {
    try {
      const { data } = await this.propertyApi.deleteProperty(id)
      if (!data.ok) {
        throw new Error('API Error')
      }
      return
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async uploadPropertyImage(imageFile: File): Promise<string> {
    const uuidData = await this.fileApi.getUuid()
    const uuid = uuidData.data.data.uuid
    const sasData = await this.fileApi.getSas(uuid, imageFile.name)
    const client = new BlockBlobClient(sasData.data.data.sas)
    await client.uploadData(imageFile, {
      blockSize: 4 * 1024 * 1024,
      concurrency: 20,
      blobHTTPHeaders: { blobContentType: imageFile.type },
    })

    return sasData.data.data.path
  }

  async patchFloorPlans(propertyId: number, uuid: string, floors: PatchFloorEntity[]): Promise<void> {
    const requestFloors = floors.map((floor: PatchFloorEntity) => {
      return {
        id: floor.id,
        floor_plan: {
          path: floor.path,
        },
      }
    })
    const request: FloorPlansPatchRequest = {
      uuid,
      floors: requestFloors,
    }
    const { data } = await this.floorApi.patchFloorPlans(propertyId, request)
    if (!data?.data || !data.ok) {
      throw new Error('API Error')
    }
  }

  async deleteFloorPlans(propertyId: number, uuid: string, floorIds: number[]): Promise<void> {
    const requestFloors = floorIds.map(id => {
      return {
        id,
        floor_plan: null,
      }
    })
    const request: FloorPlansPatchRequest = {
      uuid,
      floors: requestFloors,
    }
    const { data } = await this.floorApi.patchFloorPlans(propertyId, request)
    if (!data?.data || !data.ok) {
      throw new Error('API Error')
    }
  }

  async fetchFloorPlanDetails(
    propertyId: number,
    floorId: number,
    options?: AxiosRequestConfig
  ): Promise<FloorEntity | null> {
    const { data } = await this.floorApi.getFloor(propertyId, floorId, options)
    if (data?.data && data.ok) {
      return toCamelCase(data.data)
    } else {
      return null
    }
  }

  async fetchFloorPlanObjects(
    propertyId: number,
    floorId: number,
    status?: number[],
    facilityType?: number[],
    options?: AxiosRequestConfig
  ): Promise<FloorPlanObjectEntity[]> {
    const { data } = await this.floorApi.getFloorFloorplanObjects(propertyId, floorId, status, facilityType, options)
    if (data?.data && data.ok) {
      const objectsResponse = data.data.map((object: FloorPlanObject): FloorPlanObjectEntity => {
        return {
          id: object.id,
          title: object.content.title,
          description: object.content.description,
          category: object.content.category,
          latitude: object.coordinate.latitude,
          longitude: object.coordinate.longitude,
          type: object.content.type,
          resourceId: object.content.resource_id,
        }
      })
      return objectsResponse
    }

    throw new Error('API Error')
  }

  /**
   *
   * @deprecated
   */
  async fetchFloorIssuesFloorPlanIssueEntity(
    propertyId: number,
    floorId: number,
    statuses?: number[],
    options?: AxiosRequestConfig
  ): Promise<FloorPlanIssueEntity[] | null> {
    const { data } = await this.floorApi.getFloorIssue(propertyId, floorId, statuses, options)
    if (data?.data && data.ok) {
      const issuesResponse = data.data.map((floorIssue: FloorIssue): FloorPlanIssueEntity => {
        return {
          id: floorIssue.id,
          content: {
            type: PvrObjectContentType.Issue,
            category: floorIssue.status,
            title: floorIssue.title,
            description: floorIssue.description,
            resourceId: `'Issue:${floorIssue.id}`,
          },
          issue: {
            ...toCamelCase(floorIssue),
            propertyId: floorIssue.attributes.referred_property.property_id,
            propertyName: floorIssue.attributes.referred_property.property_name,
            floorId: floorIssue.attributes.referred_property.floor_id || '',
            floorName: floorIssue.attributes.referred_property.floor_name || '',
          },
        }
      })
      return issuesResponse
    }

    throw new Error('API Error')
  }

  async fetchFloorIssues(
    propertyId: number,
    floorId: number,
    statuses?: number[],
    options?: AxiosRequestConfig
  ): Promise<IssueEntity[]> {
    const { data } = await this.floorApi.getFloorIssue(propertyId, floorId, statuses, options)
    if (data?.data && data.ok) {
      const issuesResponse = data.data.map((floorIssue: FloorIssue): IssueEntity => {
        return {
          ...toCamelCase(floorIssue),
          propertyId: floorIssue.attributes.referred_property.property_id,
          propertyName: floorIssue.attributes.referred_property.property_name,
          floorId: floorIssue.attributes.referred_property.floor_id || '',
          floorName: floorIssue.attributes.referred_property.floor_name || '',
        }
      })
      return issuesResponse
    }

    throw new Error('API Error')
  }

  async fetchAllDirs(propertyId: number, options?: AxiosRequestConfig): Promise<PropertyDirectoryEntity[]> {
    const { data } = await this.propertyApi.listAllPropertyDirectories(propertyId, options)
    return data.data && data.ok ? toCamelCase(data.data) : []
  }

  async fetchDirsAndFiles(
    propertyId: number,
    directoryId: number,
    options?: AxiosRequestConfig
  ): Promise<PropertyDirectoryEntity | null> {
    const { data } = await this.propertyApi.listPropertyDirectoriesAndFiles(propertyId, directoryId, options)
    if (!data?.data || !data.ok) {
      throw new Error('API Error')
    }

    const directoryFile = data.data

    if (directoryFile.attachment_files) {
      const attachments = directoryFile.attachment_files.map((file: AttachmentFile): AttachmentFileEntity => {
        // todo: always use sasThumbnail regardless status, move check to component
        const sasThumbnail = file.status === FILE_STATUS.COMPLETED ? file.sas_thumbnail : file.sas_file
        const mimeType = file.mime_type || mime.lookup(file.name)

        return {
          id: file.id,
          name: file.name,
          mimeType: mimeType || '',
          size: file.size || 0,
          sasFile: file.sas_file || '',
          sasThumbnail: sasThumbnail || '',
          userId: file.user_id,
          isProtected: !!file.is_protected,
          filePermissions: file.file_permissions,
          createdAt: file.created_at,
          contentCreatedAt: file.content_created_at,
          status: file.status,
        }
      })

      return {
        ...toCamelCase(directoryFile),
        attachmentFiles: attachments,
      }
    }

    return toCamelCase(directoryFile)
  }

  async bulkDirectoryOperation(
    propertyId: number,
    directoryId: number,
    entity: BulkFileOperationEntity
  ): Promise<void> {
    const request: PropertyDirectoryBulkOperationsRequest = {}

    const deletedFiles = entity.attachments?.deleteFiles
    if (deletedFiles?.length) {
      request.attachment_operations = {
        delete_files: deletedFiles,
      }
    }

    const updateFiles = entity.attachments?.updateFiles
    if (updateFiles?.length) {
      request.attachment_operations = {
        update_files: updateFiles,
      }
    }

    const attachments = entity.attachments?.createFiles
    if (attachments) {
      request.attachment_operations = {
        ...request.attachment_operations,
        uuid: entity.attachments?.uuid,
        create_files: attachments.map(attachment => {
          return {
            name: attachment.name,
            path: attachment.path,
            is_protected: attachment.isProtected,
          }
        }),
      }
    }

    const { data } = await this.propertyApi.propertyDirectoryBulkOperations(propertyId, directoryId, request)
    if (!data?.data || !data.ok) {
      throw new Error('API Error')
    }
  }
}
