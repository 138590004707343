





























import Vue from 'vue'
import NotificationDialog from '@/components/common/NotificationDialog.vue'
import { StoreError } from '@/types/error'

interface ErrorDisplayData {
  showDialog: boolean
  showSnackbar: boolean
  title: string
  text: string
  action: string
}

export default Vue.extend({
  name: 'ErrorDisplay',
  components: { NotificationDialog },
  data(): ErrorDisplayData {
    return {
      showDialog: false,
      showSnackbar: false,
      title: '',
      text: '',
      action: '',
    }
  },
  computed: {
    error(): StoreError {
      return this.$accessor.error.getError
    },
  },
  watch: {
    error: {
      handler(newError: StoreError): void {
        this.showDialog = newError.showDialog
        this.showSnackbar = newError.showSnackbar
        this.title = this.getErrorStatement('title')
        this.text = this.error.message ?? this.getErrorStatement('text')
        this.action = this.getErrorStatement('action')
      },
    },
  },
  methods: {
    getErrorStatement(type: string): string {
      // errorCode, statusCode, 'default'の順にi18nから取得を試みる
      const keys = [this.error.errorCode, this.error.statusCode, 'default']

      for (const key of keys) {
        // i18nで設定されていない時、指定したキーがそのまま（ここでは`error.${key}.${type}`）返ってくる
        if (this.$t(`error.${key}.${type}`).toString() !== `error.${key}.${type}`) {
          return this.$t(`error.${key}.${type}`).toString()
        }
      }

      throw new Error('Unexpected type or key')
    },
    onDialogClose(): void {
      if (this.action === this.$t('header.account.logout').toString()) {
        this.$auth.logout()
      }
      this.showDialog = false
    },
    onSnackbarClose(): void {
      this.showSnackbar = false
    },
  },
})
