import { AxiosRequestConfig } from 'axios'
import { GlobalSettingPatchRequest, SettingsApi } from '@/api/api'
import { toCamelCase, toSnakeCase } from '@/utils/common'

export interface GlobalIssueSettingEntity {
  prefix: string
  protectedFileAlias: string
}
export interface GlobalFacilitySettingEntity {
  prefix: string
}

export interface GlobaSettingEntity {
  issue: GlobalIssueSettingEntity
  facility: GlobalFacilitySettingEntity
}

export interface GlobaSettingPatchRequestEntity {
  issue?: Partial<GlobalIssueSettingEntity>
  facility?: Partial<GlobalFacilitySettingEntity>
}

export class SettingsRepository {
  private readonly settingApi: SettingsApi

  constructor(settingApi: SettingsApi) {
    this.settingApi = settingApi
  }

  async getGlobal(options?: AxiosRequestConfig): Promise<GlobaSettingEntity> {
    const { data } = await this.settingApi.getGlobalSettings(options)
    if (data?.data && data.ok) {
      return toCamelCase(data.data)
    } else {
      throw new Error('getGlobalIssueSetting API Error')
    }
  }

  async patchGlobal(settings: GlobaSettingPatchRequestEntity): Promise<GlobaSettingEntity> {
    const request: GlobalSettingPatchRequest = toSnakeCase(settings)
    const { data } = await this.settingApi.patchGlobalSettings(request)

    if (data?.data && data.ok) {
      return toCamelCase(data.data)
    } else {
      throw new Error('patchGlobalIssueSetting API Error')
    }
  }
}
