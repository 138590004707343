import { Context } from '@nuxt/types'
import { NavigationGuardNext, Route } from 'vue-router'

export default ({ app }: Context): void => {
  window.onpopstate = () => {
    app.$accessor.session.setHistoryBack(true)
  }

  app.router?.beforeEach((to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
    if (app.$accessor.session.isHistoryBack) {
      to.params.isHistoryBack = 'true'
    }
    app.$accessor.session.setHistoryBack(false)
    next()
  })
}
